import { useState } from "react";
import {
  Navbar,
  Footer,
  LectureProfileCard,
  Header,
  TableComps,
  NavTabs,
} from "../components";
import { lecturersProfile } from "../constants";
import { LecProfileTableData, LecProfileTableCol } from "../constants";
import { FaSearch } from "react-icons/fa";
import { styles } from "../styles";

const TenagaPengajarPage = () => {
  const TableFK = () => {
    const [search, setSearch] = useState("");
    return (
      <div className="w-full">
        <div className="flex flex-row items-center justify-center w-full gap-2 px-4 py-3 mb-6 border border-neutral-70">
          <FaSearch className="stroke-1 fill-neutral-80" />
          <input
            type="text"
            className="w-full focus:outline-0 focus:w-full placeholder:text-neutral-80 placeholder:"
            placeholder="Cari dosen, fakultas..."
            id="search"
            onChange={(e) => setSearch(e.target.value.toLowerCase())}
          />
        </div>
        <p
          className={`hidden ${styles.textSMedium} text-danger-40 mb-2 sm:block`}
        >
          <i>*Scroll ke kanan jika ingin melihat kolom prodi</i>
        </p>
        <div className="smd:overflow-auto">
          <TableComps
            data={LecProfileTableData.filter((tab) => {
              return search.toLowerCase() === ""
                ? tab.faculty === "fk"
                : (tab.name.toLowerCase().includes(search) ||
                    tab.studyProg.toLowerCase().includes(search)) &&
                    tab.faculty === "fk";
            })}
            columns={LecProfileTableCol}
            customization="w-full "
          />
        </div>
      </div>
    );
  };

  const TableFBTS = () => {
    const [search, setSearch] = useState("");
    return (
      <div className="w-full">
        <div className="flex flex-row items-center justify-center w-full gap-2 px-4 py-3 mb-6 border border-neutral-70">
          <FaSearch className="stroke-1 fill-neutral-80" />
          <input
            type="text"
            className="w-full focus:outline-0 focus:w-full placeholder:text-neutral-80 placeholder:"
            placeholder="Cari dosen, fakultas..."
            id="search"
            onChange={(e) => setSearch(e.target.value.toLowerCase())}
          />
        </div>
        <p
          className={`hidden ${styles.textSMedium} text-danger-40 mb-2 sm:block`}
        >
          <i>*Scroll ke kanan jika ingin melihat kolom prodi</i>
        </p>
        <div className="smd:overflow-auto">
          <TableComps
            data={LecProfileTableData.filter((tab) => {
              return search.toLowerCase() === ""
                ? tab.faculty === "fbts"
                : (tab.name.toLowerCase().includes(search) ||
                    tab.studyProg.toLowerCase().includes(search)) &&
                    tab.faculty === "fbts";
            })}
            columns={LecProfileTableCol}
            customization="w-full "
          />
        </div>
      </div>
    );
  };

  const Pimpinan = () => {
    return (
      <>
        <section className="flex flex-col justify-center items-center gap-[80px] md:gap-[40px] sm:gap-[8px]">
          <div>
            {/* Rektor */}
            <h1
              className={`text-[48px] font-medium text-primary-40 text-center mb-10 md:text-[36px] md:mb-4 sm:text-[24px] sm:mt-4`}
            >
              Rektor
            </h1>
            <div className="w-full flex flex-wrap items-start justify-center gap-[160px]">
              {lecturersProfile
                .filter((lec) => lec.id === 1)
                .map((lec) => {
                  return <LectureProfileCard {...lec} />;
                })}
            </div>
            {/* Wakil Rektor */}
            <h1
              className={`text-[48px] font-medium text-primary-40 text-center mb-10 mt-20 md:text-[36px] md:mb-4 sm:text-[24px] sm:mb-8 sm:mt-4`}
            >
              Wakil Rektor
            </h1>
            <div className="w-full flex flex-wrap items-start justify-center gap-[160px] md:gap-[32px] sm:gap-0">
              {lecturersProfile
                .filter((lec) => lec.id === 2)
                .map((lec) => {
                  return <LectureProfileCard {...lec} />;
                })}
            </div>
          </div>

          <div className="border-b border-primary-40 w-[160px]"></div>

          <div>
            {/* Dekan */}
            <h1
              className={`text-[48px] font-medium text-primary-40 text-center mb-10 mt-20 md:text-[36px] md:mb-4 sm:text-[24px] sm:mb-8 sm:mt-4`}
            >
              Dekan
            </h1>
            <div className="w-full flex flex-wrap items-start justify-center gap-[160px] md:gap-[32px] sm:gap-0">
              {lecturersProfile
                .filter((lec) => lec.id === 3)
                .map((lec) => {
                  return <LectureProfileCard {...lec} />;
                })}
            </div>

            {/* Ketua Lembaga */}
            <h1
              className={`text-[48px] font-medium text-primary-40 text-center mb-10 mt-20 md:text-[36px] md:mb-4 sm:text-[24px] sm:mb-8 sm:mt-4`}
            >
              Ketua Lembaga
            </h1>
            <div className="w-full flex flex-wrap items-start justify-center gap-[160px] md:gap-[36px] sm:gap-0">
              {lecturersProfile
                .filter((lec) => lec.id === 4)
                .map((lec) => {
                  return <LectureProfileCard {...lec} />;
                })}
            </div>

            {/* Kaprodi */}
            <h1
              className={`text-[48px] font-medium text-primary-40 text-center mb-10 mt-20 md:text-[36px] md:mb-4 sm:text-[24px] sm:mb-8 sm:mt-4`}
            >
              Kepala Prodi
            </h1>
            <div className="w-full flex flex-wrap items-start justify-center gap-[160px] md:gap-[36px] sm:gap-0">
              {lecturersProfile
                .filter((lec) => lec.id === 5)
                .map((lec) => {
                  return <LectureProfileCard {...lec} />;
                })}
            </div>

            {/* Ketua Gugus */}
            <h1
              className={`text-[48px] font-medium text-primary-40 text-center mb-10 mt-20 md:text-[36px] md:mb-4 sm:text-[24px] sm:mb-8 sm:mt-4`}
            >
              Ketua Gugus
            </h1>
            <div className="w-full flex flex-wrap items-start justify-center gap-[160px] md:gap-[36px] sm:gap-0">
              {lecturersProfile
                .filter((lec) => lec.id === 6)
                .map((lec) => {
                  return <LectureProfileCard {...lec} />;
                })}
            </div>

            {/* Ketua Unit */}
            <h1
              className={`text-[48px] font-medium text-primary-40 text-center mb-10 mt-20 md:text-[36px] md:mb-4 sm:text-[24px] sm:mb-8 sm:mt-4`}
            >
              Ketua Unit
            </h1>
            <div className="w-full flex flex-wrap items-start justify-center gap-[160px] md:gap-[36px] sm:gap-0">
              {lecturersProfile
                .filter((lec) => lec.id === 7)
                .map((lec) => {
                  return <LectureProfileCard {...lec} />;
                })}
            </div>
          </div>
        </section>
      </>
    );
  };

  const tabs = [
    {
      label: "Pimpinan",
      content: <Pimpinan />,
    },
    {
      label: "Fakultas Kesehatan",
      content: <TableFK />,
    },
    {
      label: "Fakultas Bisnis Teknologi dan Sosial",
      content: <TableFBTS />,
    },
  ];

  return (
    <div className="flex flex-col items-center justify-center">
      <Navbar />
      <Header title="Tenaga Pengajar" />
      <NavTabs tabs={tabs} />
      <Footer />
    </div>
  );
};

export default TenagaPengajarPage;
