import { Footer, Header, Navbar, BeasiswaCard } from "../components";
import { beasiswa } from "../constants";

const BeasiswaPage = () => {
  return (
    <>
      <Navbar />
      <Header title="Beasiswa"/>
      <main className="flex flex-wrap items-center justify-center gap-3 py-10 px-36">
        <div className="container flex flex-wrap items-center justify-center max-w-fill gap-x-5 gap-y-16">
        {beasiswa.map((bea) => {
            return (
              <BeasiswaCard
                {...bea}
              />
            );
          })}
        </div>
      </main>
      <Footer />
    </>
  );
};

export default BeasiswaPage;
