import { Navbar, Header, Footer, ImageAccordion } from "../components";
import { useState } from "react";
import { tuitonAccordion } from "../constants";
import { styles } from "../styles";

const BiayaPendidikanPage = () => {
  const [accordions, setAccordion] = useState(tuitonAccordion);

  const toggleAccordion = (accordionkey) => {
    const updatedAccordions = accordions.map((accord) => {
      if (accord.key === accordionkey) {
        return { ...accord, isOpen: !accord.isOpen };
      } else {
        return { ...accord, isOpen: false };
      }
    });

    setAccordion(updatedAccordions);
  };

  return (
    <>
      <Navbar />
      <Header title="Biaya Pendidikan" />
      <main className={`${styles.paddingX} flex flex-col gap-6 py-10`}>
        <div>
          {accordions.map((accordion) => (
            <ImageAccordion
              {...accordion}
              toggleAccordion={() => toggleAccordion(accordion.key)}
            />
          ))}
        </div>
      </main>
      <Footer />
    </>
  );
};

export default BiayaPendidikanPage;
