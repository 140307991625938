import { Navbar, Header, Footer, Accordion } from "../components";
import { useState } from 'react';
import { faqAccordion } from '../constants';
import { styles } from "../styles";

const FaqPage = () => {
  const [accordions, setAccordion] = useState(faqAccordion);

  const toggleAccordion = (accordionkey) => {
    const updatedAccordions = accordions.map((accord) => {
        if (accord.key === accordionkey) {
            return { ...accord, isOpen: !accord.isOpen };
        } else {
            return { ...accord, isOpen: false };
        }
    });

    setAccordion(updatedAccordions);
  };

  return (
    <>
      <Navbar/>
      <Header title="FAQ" />
      <main className={`${styles.paddingX} flex flex-col gap-6 py-10`}>
        <div>
                {accordions.map((accordion) => (
                    <Accordion
                        key={accordion.key}
                        title={accordion.title}
                        data={accordion.data}
                        isOpen={accordion.isOpen}
                        list={accordion?.list}
                        toggleAccordion={() => toggleAccordion(accordion.key)}
                    />
                ))}
            </div>
      </main>
      <Footer/>
    </>
  )
}

export default FaqPage
