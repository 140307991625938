import { Link } from "react-router-dom";
import { PiMapPinLight, PiWhatsappLogoLight, PiEnvelopeOpenLight } from "react-icons/pi";

import { socialMedias, footerLinks } from "../../constants";
import { logowhite } from "../../assets";
import { styles } from "../../styles";

const Footer = () => {
  return (
    <footer
      className={`${styles.paddingX} w-full grid place-items-center bg-primary-40 py-4`}
    >
      <div className="w-full max-w-[1150px] grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 smd:gap-4 md:gap-y-8 text-white">
        <div className="flex flex-col justify-center gap-6">
          <h1 className={`${styles.textXLMedium} text-center`}>Admission</h1>
          <a
            href="https://maps.app.goo.gl/poXJPSTLbhhJFprK9"
            target="_blank"
            rel="noreferrer"
          >
            <div className="flex items-center gap-4">
              <PiMapPinLight className="w-6 h-6" />
              <p className="w-full">
                Jl. Perintis Kemerdekaan Km 13, 7, Paccerakkang, Biringkanaya,
                Makassar, Sulawesi Selatan, Indonesia
              </p>
            </div>
          </a>
          <a
            href="https://mail.google.com/mail/u/0/?fs=1&to=rektorat@univeral.ac.id&su=&body=&tf=cm"
            target="_blank"
            rel="noreferrer"
          >
            <div className="flex items-center gap-4">
              <PiEnvelopeOpenLight className="w-6 h-6" />
              <p className="w-full">rektorat@univeral.ac.id</p>
            </div>
          </a>
          <a
            href="https://api.whatsapp.com/send?phone=+62882019436805&text=Saya%20tertarik%20untuk%20mengetahui%20lebih%20banyak%20mengenai%20Univeral!"
            target="_blank"
            rel="noreferrer"
          >
            <div className="flex items-center gap-4">
              <PiWhatsappLogoLight className="w-6 h-6" />
              <p className="w-full">+62 8201 9436 805</p>
            </div>
          </a>
        </div>
        <div className="grid place-items-center">
          <img src={logowhite} alt="logo" className="w-60" />
        </div>
        <div className="flex flex-col justify-center gap-8 md:col-span-2">
          <div className="flex flex-wrap items-center justify-center gap-6">
            {socialMedias.map((media) => {
              return (
                <Link to="/redirect" state={{ url: media.link }}>
                  <media.image
                    className="w-6 h-6 cursor-pointer"
                    key={media.name}
                  />
                </Link>
              );
            })}
          </div>
          <ul className="flex flex-wrap items-center justify-center gap-3 list-none">
            {footerLinks.map(({ name, link }) => {
              return (
                <Link to={link}>
                  <li
                    className="no-underline cursor-pointer hover:underline"
                    key={name}
                  >
                    {name}
                  </li>
                </Link>
              );
            })}
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
