import { AchievementCard, Navbar, Header, Footer } from "../components";
import { Button } from "../components";
import { FaChevronRight } from "react-icons/fa";
import { achievement } from "../constants";
import { styles } from "../styles";

const PenghargaanPage = () => {
  return (
    <>
      <Navbar />
      <Header title="Penghargaan" />
      <main className={`${styles.paddingX} flex flex-col items-center justify-center gap-3 py-10 smd:flex-col`}>
        <div
          className="flex flex-wrap items-center justify-center gap-3"
        >
          {achievement.map(({ img, title, path }) => {
            return <AchievementCard img={img} title={title} path={path} />;
          })}
        </div>
        <Button
          title="Lihat Selengkapnya"
          icon={<FaChevronRight />}
          bg="bg-primary-40"
          text="text-neutral-10"
          customization="text-neutral-10 bg-primary-40 mt-8"
          type="submit"
          onClick={(evt) => evt.preventDefault()}
        />
      </main>
      {/* <div className="flex flex-wrap items-center justify-center gap-3 pb-10">
      </div> */}
      <Footer />
    </>
  );
};

export default PenghargaanPage;
