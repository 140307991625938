import { useParams, Navigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { FaFileDownload } from "react-icons/fa";
import { Footer, Header, Navbar, SiluetCard, Button } from "../components";
import { programStudi } from "../constants";
import { styles } from "../styles";

const ProgramPage = () => {
  const { depart } = useParams();
  const chosenProgram = useRef({});
  const isFound = useRef(false);
  const [isLoading, setIsLoading] = useState(true);
  const handleDownload = () => {
    const link = document.createElement('a');
    link.download = `${kurikulum}`;

    link.href = chosenProgram.current.details.kurikulum;
    link.click();
  };

  useEffect(() => {
    chosenProgram.current = programStudi.filter((program) => {
      const page = program.link.split("/").at(-1);
      return depart === page;
    })[0];

    isFound.current = Object.keys(chosenProgram.current).length > 0;
    setIsLoading(false);
  }, [depart]);

  if (isLoading) return <div className={`${styles.headingL} grid w-screen h-screen place-items-center text-primary-40`}>Loading...</div>;

  if (!isLoading && !isFound) return <Navigate to="/error"></Navigate>

  const { subject, details } = chosenProgram.current;
  const { desc, listDeskripsi, karir, listKarir, keunggulan, image, kurikulum } = details;
  return (
    <>
      <Navbar />
      <Header title={subject} />
      <main className={`${styles.paddingX} py-10`}>
        <div>
          <h1
            className={`${styles.headingL} text-center text-primary-40 font-bold`}
          >
            {subject}
          </h1>
          <br />
          <div className="flex flex-col gap-4">
            <div>
              <p className={`${styles.textLMedium} text-primary-40 font-bold`}>
                {desc}
              </p>
            </div>

            <div className="flex flex-col gap-4">
              <p>{keunggulan}</p>
              <ol
                className="flex flex-col"
                style={{ listStyleType: "decimal" }}
              >
                {listDeskripsi.map((list, index) => (
                  <li className="ml-4" key={index}>
                    <div className={`${styles.textLMedium} text-primary-40 font-bold mb-2`}>
                      {list.subTitle}
                    </div>
                    <ul className="mb-2">
                      <ol className="flex flex-col ml-4"
                        style={{ listStyleType: "disc" }}>
                        {list.descTitle.map((subItem, subIndex) => (
                          <li key={subIndex}>{subItem}</li>
                        ))}
                      </ol>
                    </ul>
                  </li>
                ))}
              </ol>
            </div>
          </div>
          <br />

          <div className="flex flex-col gap-4">
            <div>
              <p className={`${styles.textLMedium} text-primary-40 font-bold`}>
                {karir}
              </p>
            </div>

            <div>
              <ol
                className="flex flex-col"
                style={{ listStyleType: "decimal" }}
              >
                {listKarir.map((list, index) => (
                  <li className="ml-4" key={index}>
                    {list}
                  </li>
                ))}
              </ol>
            </div>
            <br></br>
              <Button
                onClick={handleDownload}
                title="Download Kurikulum"
                icon={<FaFileDownload />}
                customization={`${styles.textLRegular} bg-primary-40 hover:bg-primary-50 text-white w-fit self-center`}
              />
            
          </div>
        </div>
      </main>
      <SiluetCard />
      <Footer />
    </>
  );
};

export default ProgramPage;
