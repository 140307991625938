import { useNavigate } from "react-router-dom";
import { banner } from "../../assets";
import { styles } from "../../styles";
import { Button } from "../";
import { FaChevronRight } from "react-icons/fa";

const SiluetCard = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        background: `linear-gradient(to bottom, #920E3490, #920E3490), url(${banner})center/cover no-repeat`,
      }}
      className={`${styles.paddingX} w-full h-full py-6 relative flex items-center text-white`}
    >
      <div className="flex flex-col justify-center gap-4">
        <p className={`${styles.textXLRegular} text-[#FFF1D1]`}>
          Ayo, waktunya meraih masa depanmu!
        </p>
        <p className="max-w-[75%] smd:max-w-full">
          Daftarkan dirimu sekarang dan bersiaplah untuk mengukir sejarah di
          Universitas Almarisah Madani
        </p>
        <div className="flex w-full">
          <Button
            title="Daftar Sekarang"
            icon={<FaChevronRight />}
            customization="text-neutral-10 border-solid border border-neutral-40"
            type="submit"
            onClick={(evt) => {
              evt.preventDefault();
              navigate("/redirect", {
                state: { url: "https://pmb.univeral.ac.id/" },
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SiluetCard;
