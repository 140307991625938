import { rektor } from "../assets";
import { Header, Navbar, Footer } from "../components";
import { styles } from "../styles";

const SambutanPage = () => {
  return (
    <>
      <Navbar />
      <Header title="Sambutan Rektor" />

      <section
        className={`${styles.paddingX} flex sm:flex-col w-full gap-8 py-10 text-neutral-100`}
      >
        <div className="flex flex-col items-center w-full gap-4">
          <img src={rektor} alt="rektor univeral" className="max-w-[280px]" />
          <div className="flex flex-col items-center gap-2">
            <h3 className={`${styles.headingS} text-primary-40`}>DR. Nursamsiar. M.Si.</h3>
            <p className={`${styles.textLRegular}`}>
              Rektor Universitas Almarisah Madani
            </p>
          </div>
        </div>
        <div>
          <p>
            Saya, DR. Nursamsiar. M.Si., dengan rasa hormat dan kebanggaan
            menyambut Anda di pintu gerbang pengetahuan dan keunggulan. Di
            Universitas ini, kami percaya bahwa pendidikan adalah kunci untuk
            membuka pintu masa depan yang cerah dan membentuk pemimpin masa
            depan yang tangguh. Oleh karena itu, kami berkomitmen untuk
            memberikan pengalaman pendidikan yang berkualitas tinggi dan
            mendukung perkembangan pribadi Anda. <br />
            <br /> Visi kami di Universitas Almarisah Madani adalah menjadi
            pusat keunggulan dalam pendidikan, penelitian, dan pelayanan
            masyarakat. Kami bermimpi untuk menciptakan lulusan yang tidak hanya
            memiliki pengetahuan yang mendalam dalam bidang akademik mereka,
            tetapi juga memiliki keterampilan interpersonal, etika, dan visi
            yang luas untuk membuat perbedaan positif dalam dunia ini. <br />
            <br /> Misi kami adalah memberikan pendidikan yang relevan,
            inovatif, dan berorientasi pada nilai, serta memfasilitasi
            penelitian yang mendorong perkembangan ilmu pengetahuan dan
            masyarakat. Kami ingin berkolaborasi dengan komunitas lokal dan
            global untuk mengatasi tantangan-tantangan global dan mewujudkan
            visi kami bersama. Di Universitas Almarisah Madani, kami
            memprioritaskan kualitas dan inklusivitas. Kami berkomitmen untuk
            menciptakan lingkungan yang mendukung keberagaman dan inklusi, di
            mana setiap individu dihargai dan memiliki peluang yang sama untuk
            sukses. Kami memahami bahwa keberagaman ide dan pandangan adalah
            kekuatan, dan kami mendorong dialog yang konstruktif dan kerja sama
            antara semua anggota komunitas universitas. <br />
            <br /> Saya ingin mengajak Anda, mahasiswa, dosen, staf, dan
            pengunjung situs web ini, untuk berpartisipasi aktif dalam
            perjalanan kami menuju keunggulan dan harmoni. Mari kita
            bersama-sama menciptakan masa depan yang cerah dan memberikan
            kontribusi positif bagi masyarakat dan dunia. <br />
            <br /> Terakhir, saya ingin mengucapkan terima kasih kepada semua
            civitas akademik universitas kami yang telah berdedikasi dan bekerja
            keras untuk mewujudkan visi kami. Bersama-sama, kita akan meraih
            prestasi yang luar biasa. <br />
            <br />
            Terima kasih atas kunjungan Anda dan selamat bergabung dalam
            perjalanan pendidikan dan penemuan di Universitas Almarisah Madani!
          </p>
          <br />
          <p>Hormat saya,</p>
          <p>DR. Nursamsiar M.Si.,</p>
          <p>Rektor, Universitas Almarisah Madani</p>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default SambutanPage;
