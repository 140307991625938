import { EventCard, Footer, Header, Navbar } from "../components";
import { events } from "../constants";
import { styles } from "../styles";

const KegiatanAcaraPage = () => {
  return (
    <>
      <Navbar />
      <Header title="Kegiatan & Acara" />
      <main className={`${styles.paddingX} flex flex-wrap items-center justify-center gap-12 py-10`}>
        {events.map((event) => {
          return <EventCard {...event} />;
        })}
      </main>
      <Footer />
    </>
  );
};

export default KegiatanAcaraPage;
