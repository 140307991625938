import { navLinks } from "../../constants";
import { logo } from "../../assets";
import { FaChevronDown, FaExternalLinkAlt } from "react-icons/fa";
import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { styles } from "../../styles";

const Navbar = ({ mref }) => {
  const navigate = useNavigate();
  const [links, setLinks] = useState(navLinks);
  const [currPage, setCurrPage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownToggled, setDropdownToggled] = useState(false);

  useEffect(() => {
    setCurrPage(window.location.pathname);
  }, []);

  const handleOnClick = (path) => {
    setDropdownToggled(false);
    navigate(path);
  };

  const handleToggleDropdown = ({ id }) => {
    const updatedLinks = links.map((link) => {
      if (link.id === id) return { ...link, isActive: !link.isActive };
      else return { ...link, isActive: false };
    });
    setLinks(updatedLinks);
  };

  return (
    <nav
      className={`${styles.paddingX}  flex w-full h-[64px] items-center justify-between z-20 px-[50px] text-primary-90 bg-neutral-10 sticky top-0`}
      ref={mref}
    >
      <Link to="/" className="flex gap-[16px] items-center justify-center">
        <img src={logo} alt="logo" className="h-[40px]" />
        <p className="font-medium text-primary-40 sm:hidden">
          Universitas Almarisah Madani
        </p>
      </Link>

      <ul className="flex items-center justify-center list-none smd:hidden">
        {links.map((link) => {
          return (
            <li
              className={`px-[24px] font-medium text-primary-40 flex items-center justify-center gap-1 hover:bg-neutral-40 cursor-pointer h-16 transition ease-in-out duration-150 relative ${
                link.isActive ? "bg-neutral-40" : ""
              } ${
                ["/", "/kontak"].includes(currPage) &&
                link.path === currPage &&
                "border-b-[3px] border-primary-40"
              }`}
              key={link.id}
              onClick={
                ["beranda", "kontak"].includes(link.id)
                  ? () => {
                      handleOnClick(link.path);
                    }
                  : () => {
                      handleToggleDropdown(link);
                    }
              }
            >
              <p>{link.title}</p>
              {!["beranda", "kontak"].includes(link.id) && (
                <FaChevronDown
                  className={`${
                    link.isActive && "rotate-180"
                  } transition ease-in-out duration-150`}
                />
              )}

              {/* Dropdown */}
              {link.child !== undefined && (
                <ul
                  className={`absolute right-0 flex flex-col divide-y w-max top-0 mt-16 ${
                    !link.isActive && "hidden"
                  }`}
                >
                  {link.child.map((option) => {
                    const { id, title, path } = option;
                    return ["penerimaan-mahasiswa"].includes(id) ? (
                      <Link to="/redirect" state={{ url: path }}>
                        <li
                          className="px-4 py-3 bg-neutral-10 h-[48px] flex gap-4 items-center hover:bg-neutral-40 cursor-pointer transition ease-in-out duration-150"
                          key={id}
                        >
                          <p>{title}</p>
                          <FaExternalLinkAlt />
                        </li>
                      </Link>
                    ) : (
                      <Link to={path}>
                        <li
                          className="px-4 py-3 bg-neutral-10 h-[48px] flex gap-4 items-center hover:bg-neutral-40 cursor-pointer transition ease-in-out duration-150"
                          key={id}
                        >
                          <p>{title}</p>
                        </li>
                      </Link>
                    );
                  })}
                </ul>
              )}
            </li>
          );
        })}
      </ul>

      {/* Tablet Navbar */}
      {/* Hamburger */}
      <div className="hidden smd:block">
        <div
          className="relative w-[32px] h-[32px] flex flex-col items-center justify-around"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <span
            className={`h-1 w-full rounded-[10px] bg-primary-40 ${
              isOpen ? "rotate-[-45deg]" : "rotate-0"
            } transtition-transform duration-300 origin-[top_right]`}
          ></span>
          <span
            className={`h-1 w-full rounded-[10px] bg-primary-40 ${
              isOpen ? "opacity-0" : "opacity-100"
            } transtition-transform duration-200`}
          ></span>
          <span
            className={`h-1 w-full rounded-[10px] bg-primary-40 ${
              isOpen ? "rotate-45" : "rotate-0"
            } transtition-transform duration-300 origin-[bottom_right]`}
          ></span>
        </div>
        <ul
          className={`${
            styles.textLMedium
          } absolute top-0 left-0 w-full mt-[64px] h-[100vh] divide-y text-primary-40 ${
            isOpen ? "translate-x-0" : "translate-x-[-150%]"
          } transtition-transform duration-200`}
        >
          {links.map((link) => {
            return (
              <>
                <li
                  className={`${styles.paddingX} flex items-center justify-between w-full h-12 bg-neutral-10`}
                  key={link.title}
                  onClick={() => {
                    handleOnClick(link.path);
                    handleToggleDropdown(link);
                  }}
                >
                  <p>{link.title}</p>
                  {link.child !== undefined && (
                    <FaChevronDown
                      className={`${
                        link.isActive ? "rotate-180" : "rotate-0"
                      } transition-transform duration-300`}
                    />
                  )}
                </li>

                {/* Dropdown */}
                {link.child !== undefined && (
                  <ul
                    className={`flex flex-col divide-y w-full ${
                      !link.isActive ? "hidden" : ""
                    } transtition-transform duration-200`}
                  >
                    {link.child.map((option) => {
                      const { id, title, path } = option;
                      return ["penerimaan-mahasiswa"].includes(id) ? (
                        <Link to="/redirect" state={{ url: path }}>
                          <li
                            className="px-20 sm:px-10 py-3 bg-neutral-30 h-[48px] flex justify-between items-center hover:bg-neutral-40 cursor-pointer transition ease-in-out duration-150"
                            key={id}
                          >
                            <p>{title}</p>
                            <FaExternalLinkAlt />
                          </li>
                        </Link>
                      ) : (
                        <Link to={path}>
                          <li
                            className="px-20 sm:px-10 py-3 bg-neutral-30 h-[48px] flex justify-between items-center hover:bg-neutral-40 cursor-pointer transition ease-in-out duration-150"
                            key={id}
                          >
                            <p>{title}</p>
                          </li>
                        </Link>
                      );
                    })}
                  </ul>
                )}
              </>
            );
          })}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
