import { FaFileDownload } from "react-icons/fa";
import { Navbar, Header, Footer, Button } from "../components";
import { styles } from "../styles";
import { alamatKost, katalogKost } from "../assets";

const InformasiAkomodasiPage = () => {
  return (
    <>
      <Navbar />
      <Header title="Informasi Akomodasi" />
      <main className={`${styles.paddingX} flex flex-col gap-6 py-10`}>
        <p className={styles.textLMedium}>
          Dengan adanya beragam pilihan akomodasi yang tersebar di berbagai
          lokasi di Kota Makassar, Mahasiswa dan Mahasiswi yang berasal dari
          luar kota atau pulau memiliki aksesibilitas yang lebih baik dalam
          mengejar pendidikan mereka di <b className="text-primary-40">UNIVERAL</b>. Tarif akomodasi yang
          ditawarkan bervariasi, bergantung pada ukuran kamar dan kelengkapan
          fasilitas yang disediakan.
        </p>

        <Button
          title="Download Brosur"
          icon={<FaFileDownload />}
          customization={`${styles.textLRegular} bg-primary-40 hover:bg-primary-50 text-white w-fit self-center`}
        />

        {/* <img src={alamatKost} alt="alamat" />
        <img src={katalogKost} alt="katalog" /> */}
      </main>
      <Footer />
    </>
  );
};

export default InformasiAkomodasiPage;
