import { FaRegCalendarAlt, FaRegClock } from "react-icons/fa";
import { styles } from "../../styles";
import { FaChevronRight } from "react-icons/fa";
import Button from "../Button";

const EventCard = (props) => {
  return (
    <div className="flex flex-col items-center justify-center bg-neutral-10 border-neutral-40 w-[400px] h-[456px] border-[1px]">
      <img
        src={props.img}
        alt=""
        className="w-[400px] h-[300px] object-cover"
      />
      <div className="flex flex-col items-start justify-start w-full gap-6 p-6">
        <div className="flex flex-col items-start self-stretch gap-2">
          <h1 className={`${styles.headingM} text-neutral-90`}>
            {props.title}
          </h1>
          <div className="flex gap-6 divide-x-2">
            <div className="flex gap-1">
              <FaRegCalendarAlt className="w-[16px] h-[16px] fill-info-90" />
              <p className={`${styles.textSRegular} text-info-90`}>
                {props.date}
              </p>
            </div>
            <div className="flex gap-1 pl-6">
              <FaRegClock className="w-[16px] h-[16px] fill-info-90" />
              <p className={`${styles.textSRegular} text-info-90`}>
                {props.time}
              </p>
            </div>
          </div>
        </div>
        {/* <button
          className={`${styles.textLMedium} hover:underline flex items-center justify-center gap-1 rounded text-primary-40`}
        >
          <p>Lihat selengkapnya</p>
          <img src={chevright} className="h-5" alt="right arrow" />
        </button> */}
        <Button
          title="Lihat Selengkapnya"
          customization="hover:underline text-primary-40 ml-[-24px]"
          icon={<FaChevronRight />}
        />
      </div>
    </div>
  );
};

export default EventCard;
