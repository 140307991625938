import { styles } from "../../styles";
import { Link } from "react-router-dom";

const AchievementCard = (props) => {
  return (
    <div className="shadow-xl flex flex-col justify-center items-center w-[345px]">
      <img src={props.img} alt="" className="max-h-[350px] w-full object-cover" />

      <div className="flex flex-col justify-center w-full">
        <div className="flex flex-col justify-center self-stretch px-3 h-[90px]">
          <p
            className={`${styles.textL} text-center text-neutral-90 font-bold line-clamp-2`}
          >
            {props.title}
          </p>
          <Link
            to={props.path}
            className={`${styles.textSMedium} text-center text-info-80 hover:underline italic pb-1`}
          >
            Selengkapnya &#62;
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AchievementCard;
