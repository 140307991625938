import {
  PiWhatsappLogoLight,
  PiTelegramLogoLight,
  PiTiktokLogoLight,
  PiYoutubeLogoLight,
  PiInstagramLogoLight,
  PiFacebookLogoLight,
  PiTwitterLogoLight,
} from "react-icons/pi";

import {
  placeholder1,
  jalursukses,
  ubc,
  fac1,
  fac2,
  fac3,
  fac4,
  fac5,
  fac6,
  fac7,
  fac8,
  penghargaan1,
  beasiswa1,
  beasiswa2,
  beasiswa3,
  beasiswa4,
  beasiswa5,
  beasiswa6,
  beasiswa7,
  beasiswa8,
  beasiswa9,
  pmb1,
  pmb2,
  pmb3,
  pmb4,
  pmb5,
  pmb6,
  pmb7,
  pmb8,
  pmb9,
  pmb10,
  pmb11,
  logo,
  tuition1,
  tuition2,
  lecturer,
  apoteker,
  s1farmasi,
  manajemen,
  akuntansi,
  hukum,
  sisteminformasi,
  informatika,
  ilmukomunikasi,
  d3farmasi,
  d3kebidanan,
  landingheader1,
  landingheader2,
  landingheader3,
  landingheader4,
  rektor,
  rektorProfile,
  wr1,
  // wr2,
  wr3,
  lembagaPMI,
  lembagaPPM,
  kaprodiAkuntansi,
  kaprodiD3Farmasi,
  kaprodiD3Kebidanan,
  kaprodiIlkom,
  kaprodiIlmuHukum,
  kaprodiInfor,
  kaprodiManajemen,
  kaprodiPSPPA,
  kaprodiS1Farmasi,
  kaprodiSisfor,
  ketuaGugusFBTS,
  ketuaGugusFK,
  kuppmFBTS,
  kuppmFK,
  dekanfbts,
  dekanfk,
  ucsi,
  uistinye,
  umahsa,
  umara,
  umsu,
  usainsmy,
  utmu,
  utoyama,
  uzaim,
  aam1,
  aam2,
  biofarma,
  brivit1,
  brivit2,
  bsi,
  combiphar,
  dipa,
  herosupermarket,
  ismut,
  mensa,
  natura,
  penta,
  royal,
  artikel1p1,
  artikel2p1,
  kurikulumHukum,
  kurikulumApotekernew,
  kurikulumFarmasi,
  kurikulumManajemen,
  kurikulumAkuntansi,
  kurikulumInformasi,
  kurikulumInformatika,
  kurikulumKomunikasi,
  kurikulumd3farmasi,
  kurikulumd3kebidanan,
} from "../assets";

export const navLinks = [
  {
    id: "beranda",
    title: "Beranda",
    path: "/",
  },
  {
    id: "tentang",
    title: "Tentang",
    isActive: false,
    child: [
      {
        id: "sejarah",
        title: "Sejarah",
        path: "/sejarah",
      },
      {
        id: "sambutan-rektor",
        title: "Sambutan Rektor",
        path: "/sambutan",
      },
      {
        id: "tentang-univeral",
        title: "Tentang Univeral",
        path: "/tentang",
      },
      {
        id: "tenaga-pengajar",
        title: "Tenaga Pengajar",
        path: "/tenaga-pengajar",
      },
      {
        id: "fasilitas",
        title: "Fasilitas",
        path: "/fasilitas",
      },
      {
        id: "penghargaan",
        title: "Penghargaan",
        path: "/penghargaan",
      },
    ],
  },
  {
    id: "admission",
    title: "Admission",
    isActive: false,
    child: [
      {
        id: "penerimaan-mahasiswa",
        title: "Penerimaan Mahasiswa",
        path: "https://pmb.univeral.ac.id/",
      },
      {
        id: "biaya-pendidikan",
        title: "Biaya Pendidikan",
        path: "/biaya-pendidikan",
      },
      {
        id: "beasiswa",
        title: "Beasiswa",
        path: "/beasiswa",
      },
      // {
      //   id: "syarat-pendaftaran",
      //   title: "Syarat Pendaftaran",
      //   path: "/syarat-pendaftaran",
      // },
    ],
  },
  {
    id: "informasi",
    title: "Informasi",
    isActive: false,
    child: [
      {
        id: "lowongan-kerja",
        title: "Lowongan Kerja",
        path: "/lowongan-kerja",
      },
      {
        id: "informasi-akomodasi",
        title: "Informasi Akomodasi",
        path: "/informasi-akomodasi",
      },
      {
        id: "kegiatan-dan-acara",
        title: "Kegiatan dan Acara",
        path: "/kegiatan-acara",
      },
      {
        id: "faq",
        title: "FAQ",
        path: "/faq",
      },
      {
        id: "artikel",
        title: "Artikel",
        path: "/artikel",
      },
    ],
  },
  {
    id: "kontak",
    title: "Kontak",
    path: "/kontak",
  },
];

export const landingHeaderImgs = [
  {
    img: landingheader1,
    position: "center bottom",
  },
  {
    img: landingheader2,
    position: "center",
  },
  {
    img: landingheader3,
    position: "bottom center",
  },
  {
    img: landingheader4,
    position: "center",
  },
];

export const TabLinks = [
  {
    id: "pimpinan",
    title: "Pimpinan",
    path: "/",
  },
  {
    id: "fk",
    title: "Fakultas Kesehatan",
    path: "/",
  },
  {
    id: "fbts",
    title: "Fakultas Bisnis Teknologi dan Sosial",
    path: "/",
  },
];

export const universityStats = [
  {
    title: "Tenaga Pengajar",
    total: "90",
    textColor: "text-primary-40",
  },
  {
    title: "Mahasiswa Aktif",
    total: "1300+",
    textColor: "text-info-40",
  },
  {
    title: "Program Studi",
    total: "10",
    textColor: "text-success-40",
  },
];

export const programStudi = [
  {
    img: apoteker,
    subject: "Profesi Apoteker",
    link: "/program/profesi-apoteker",
    details: {
      desc: `Program Studi Pendidikan Profesi Apoteker merupakan program pendidikan lanjut bagi para sarjana farmasi yang telah menyelesaikan jenjang akademik sebagai upaya meningkatkan kualitas pendidikan di bidang kefarmasian dan mempersiapkan mahasiswa untuk memiliki pekerjaan dengan persyaratan keahlian khusus Program Studi Profesi Apoteker Universitas Almarisah Madani memiliki keunikan dengan ciri khas yang berbeda dari Perguruan Tinggi Lain yang dapat ditinjau dari keunggulan dalam aspek pengembangan keilmuan/keprofesian, keunggulan dalam capaian pembelajaran, dan keunggulan pada kurikulum.`,
      listDeskripsi: [
        {
          subTitle: 'Keunggulan dalam aspek pengembangan keilmuan/keprofesian',
          descTitle: ['Memiliki keunggulan networking dan kerjasama dengan institusi pendidikan tinggi farmasi dalam negeri maupun luar negeri diantaranya (Sekolah Farmasi ITB, Fakultas Farmasi UNPAD, Fakultas Farmasi UNAIR, Fakultas Farmasi UNHAS, Fakultas Farmasi UGM, Mahsa University Malaysia, Management and science University Malaysia, Taipei Medical University Taiwan, Toyama University serta Utrecht University. Selain itu, juga telah melaksanakan program Benchmark internasional di School of Pharmacy University of Sydney, Department of Pharmacy and Biomedical Sciences La Trobe University, Utrecht University Netherland.',
            `PSPPA memiliki banyak kerjasama dengan Fasilitas Pelayanan Kesehatan pemerintah maupun swasta sehingga memberikan banyak peluang bagi program studi profesi apoteker STIFA Makassar untuk implementasi tri dharma perguruan tinggi diantaranya apotek Kimia Farma, apotek Plus Group, Rumah Sakit UNHAS, RSUD Kota Makassar, RSUP Tajuddin Chalid, RSUD Labuang Baji, RSUD Haji, RSUD Salewangang, RSUD Makkasau, Seluruh Puskesmas dibawah naungan Dinas Kesehatan Kota Makassar, BPOM Makassar, Balai Kesehatan Tradisional Masyarakat Sulawesi Selatan, Badan Narkotika Nasional, Combiphar, Biofarma, Dipa Pharmalab, Nufarindo, LAFI AD, LAFI AL, Farmapol, Air Mancur, Pertiwi Agung, Natura Alam Indo, Anugrah Argon Medica, Mensa Bina Sukses, Pentavalent, Kimi Farma Trade and Distribution, Trisapta Jaya, Rajawali Nusindo, dll.`,
            `Kualifikasi dosen berpendidikan S3 dan S2 serta memiliki banyak jumlah preseptor yang kompeten yang akan mengampu mata kuliah praktik kerja di wahana praktik sehingga mendukung dalam pemenuhan target ketercapaian Standar Kompetensi Apoteker Indonesia.`,
            `Memiliki karakter dalam bidang keilmuan pelayanan kefarmasian dan bahan alam yang in line dengan visi misi institusi dan program studi sarjana farmasi dengan profil lulusan MADANI (MAnager, Decision-mAker, Negosiator, Integrity)`,
          ],
        },
        {
          subTitle: `Keunggulan dalam Kajian Capaian Pembelajaran`,
          descTitle: ['Terdapat 29 Capaian Pembelajaran Lulusan (CPL) PSPA Universitas Almarisah Madani yang dikembangkan berdasarkan profil lulusan yang meliputi unsur sikap, keterampilan umum, keterampilan khusus, dan pengetahuan yang mengacu pada Permendikbud Nomor 3 Tahun 2020 tentang Standar Nasional Pendidikan Tinggi dan Standar Kompetensi Apoteker Indonesia 2023. Keseluruhan CPL mendasari kompetensi apoteker bidang pelayanan, bidang distribusi, dan bidang produksi. Kurikulum Program Profesi Apoteker terdiri atas 31 SKS meliputi 17 SKS Mata Kuliah (semester 1) dan 14 SKS Mata Kuliah (semester dua). Mata kuliah semester satu meliputi: Praktek Kerja Profesi Apoteker (PKPA) Rumah Sakit (8 SKS), PKPA Apotek (6 SKS), PKPA Puskesmas (4 SKS). Mata kuliah semester dua meliputi PKPA Industri Farmasi (6 SKS), PKPA Distribusi farmasi (4 SKS), Uji Kompetensi Mahasiswa Program Profesi Apoteker Indonesia (UKMPPAI) (2 SKS) dan Ujian Komprehensif (1 SKS). Studi Kasus Pelayanan Kefarmasian, Industri Farmasi, Distribusi, dan pemerintahan terintegrasi dalam seluruh mata kuliah PKPA yang diselenggarakan dengan metode CBL (Case Based Learning) dan diperdalam dengan menghadirkan pakar melalui kuliah tamu, Secara keseluruhan proses praktek yang diprogram adalah sebesar 100%'],
        },
        {
          subTitle: `Prestasi Program Studi Pendidikan Profesi Apoteker (PSPPA)`,
          descTitle: [`PSPPA STIFA Makassar sebagai Institusi dengan pencapaian persentase kelulusan tertinggi (77,36%) di Indonesia Timur berdasarkan Surat Keputusan Komite Nasional Uji Kompetensi Mahasiswa Bidang Kesehatan Kementerian Pendidikan, Kebudayaan, Riset, dan Teknologi Nomor. 0970/KOM-Kes/VIII/2023 tanggal 13 Agustus 2023.`,
            `PSPPA STIFA Makassar sebagai Program Studi Profesi Apoteker yang baru dengan pencapaian persentase kelulusan tertinggi diantara PSPA baru yang lainnya berdasarkan Berita Acara Panitia Nasional Uji Kompetensi Apoteker Indonesia BA.01/PAN-UKAI/II/2023 tentang Penetapan Kelulusan Peserta Uji Kompetensi Mahasiswa Program Profesi Apoteker Indonesia Periode Februari 2023 dengan persentase tingkat kelulusan sebesar 65%.`,
          ],
        },
      ],
      karir: `Peluang Kerja :`,
      listKarir: [
        `Lembaga Pemerintahan seperti BPOM, Kemenkes, Dinkes, Puskesmas`,
        `Industri Obat, Obat Tradisional, Obat Veteriner, Kosmetika, dan Makanan Minuman`,
        `Rumah Sakit Pemerintah dan Swasta`,
        `Pedagang Besar Farmasi (PBF)`,
        `Apotek`,
        `Laboratorium Klinik`,
        `Perguruan Tinggi Negeri maupun Swasta`,
        `Peneliti`,
        `Entrepeneur Farmasi`,
        `TNI/POLRI`,
        `Pekerjaan lain yang terkait`,
      ],
      kurikulum: kurikulumApotekernew,
    },
  },
  {
    img: s1farmasi,
    subject: "Farmasi",
    link: "/program/farmasi",
    details: {
      desc: `Program Studi Sarjana Farmasi hadir guna menyelenggarakan proses pembelajaran secara komprehensif yang dapat diterapkan untuk membuat obat dari bahan alam, kosmetik, maupun obat sintetik sesuai dengan peraturan. Profesi ini memiliki keterkaitan pengetahuan yang menjadi satu bidang kompetensi unggul meliputi pengetahuan farmakologi dan farmasi klinik, Biologi Farmasi, Farmasetika dan Teknologi Farmasi, serta Analisis Farmasi dan Kimia Medisinal yang penting untuk berkontribusi dalam penemuan obat, pengembangan obat, pengujian obat dari bahan alam dan sintetik serta berfokus pada pengaplikasiannya terhadap peningkatan derajat kesehatan manusia secara aman dan efektif. Dengan kata lain, seseorang yang berprofesi dalam bidang farmasi adalah seorang pakar obat yang menguasai ilmu dan pengetahuan serta teknologi tentang obat secara mendalam dari segala aspeknya, mengusai konsep teoritis bidang pengetahuan kefarmasian secara umum dan konsep teoritis bagian khusus dalam bidang kefarmasian secara mendalam, serta mampu memformulasikan penyelesaian masalah secara prosedural dan dasar-dasar ilmu pengetahuan dan teknologi bidang kefarmasian, yang selanjutnya akan dikembangkan pada program pendidikan strata yang lebih tinggi.`,
      keunggulan: `Keunggulan Program Studi S1 Farmasi-Universitas Almarisah Madani Yaitu Lulusan Dibekali Dengan Kompetensi Keilmuan Secara Komprehensif, Berbagai kegiatan Kemahasiswaan Soft Skill dan Kewirausahaan, Pengajar Profesional, Fasilitas Pembelajaran Modern dan Masa Tunggu Bekerja Setelah Lulus Kurang Dari 6 Bulan.`,
      listDeskripsi: [
        {
          subTitle: `Keunggulan Prodi`,
          descTitle: [`Kurikulum S1 Farmasi terintegrasi Ke kurikulum Profesi Apoteker, dan Magister Farmasi`,
            `Peningkatan wawasan keilmuan melalui penyelenggaraan kuliah tamu Perguruan Tinggi dalam dan Luar Negeri serta praktisi dunia kerja`,
            `Dosen dengan lulusan dalam dan luar negeri bergelar magister dan doktor telah tersertifikasi`,
            `Metode pembelajaran yang modern terintegrasi melalui LMS (Learning Management System)`,
            `Pertukaran pelajar  `,
            `Joint Research Collaboration dari Pemerintah dalam dan luar negeri`,
          ],
        },
        {
          subTitle: `Fasilitas Pembelajaran`,
          descTitle: [`Laboratorium Farmakologi dan Farmasi Klinik`,
            `Laboratorium Biologi Farmasi`,
            `Laboratorium Analisis Farmasi dan Kimia Medisinal`,
            `Laboratorium Farmasetika dan Teknologi Farmasi`,
            `Laboratorium Penelitian Terpadu`,
            `Laboratorium Mikrobiologi Farmasi`,
            `Laboratorium Teknologi Sediaan Steril`,
            `Laboratorium CBT dan OSCE`,
            `Apotek dan Klinik Kesehatan`,
            `Inkubator Bisnis`,
            `Industri Usaha Kecil Obat Tradisional atau UKOT`,
          ],
        },
      ],
      karir: `Peluang Karir :`,
      listKarir: [
        `Peduli & Santun dalam Melayani (Care-Giver)`,
        `Pembuat Keputusan (Decision-Maker)`,
        `Komunikator (Communicator)`,
        `Manager (Manager)`,
        `Pembelajar Sepanjang Hayat (Life-Long Learner)`,
        `Pengajar (Teacher)`,
        `Pemimpin (Leader)`,
        `Peneliti (Researcher)`,
        `Pengusaha (Enterpreuner)`,
      ],
      kurikulum: kurikulumFarmasi,
    },
  },
  {
    img: manajemen,
    subject: "Manajemen",
    link: "/program/manajemen",
    details: {
      desc: `Dirancang dengan memberikan bekal kepada lulusan program studi manajemen untuk menguasai kompetensi utama di bidang manajemen yaitu Keuangan, SDM, Pemasaran, Operasional, Strategi dan Kewirausahaan. Dengan mengembangkan Proses Bisnis (Business Process Improvement), Penciptaan Nilai Pelanggan (Customer Value Creation), Pengelolaan Keuangan Berbasis Nilai (Value-Based Finance) dan Memimpin Perubahan (Leading Change). Adapun beberapa bidang yang kami tawarkan di prodi manajemen sebagai berikut:
      `,
      listDeskripsi: [
        {
          subTitle: `Manajemen Sumber Daya Manusia`,
          descTitle: [],
        },
        {
          subTitle: `Manajemen Keuangan`,
          descTitle: [],
        },
        {
          subTitle: `Manajemen`,
          descTitle: [],
        },
      ],
      karir: `Lulusan program studi Manajemen memiliki peluang bekerja di perusahan bisnis nasional dan multinasional, perusahaan swasta maupun BUMN berbagai sektor (seperti perbankan, jasa, manufaktur), start-up digital/non digital, instansi pemerintah berbagai kementerian, kepala daerah berbagai tingkatan dan posisi lainnya yang menuntut kemampuan manajerial dan kepemimpinan. Lulusan Program Studi Manajemen juga dibekali kemampuan berwirausaha maupun menjadi konsultan bisnis.
      `,
      listKarir: [],
      kurikulum: kurikulumManajemen,
    },
  },
  {
    img: akuntansi,
    subject: "Akuntansi",
    link: "/program/akuntansi",
    details: {
      desc: `Program Studi Sarjana Akuntansi  merupakan program studi  rumpun ekonomi dan bisnis yang hadir guna menyelenggarakan proses pembelajaran dan dibina oleh Universitas Almarisah Madani sebagai instituisi Pendidikan guna memenuhi kebutuhan masyarakat dalam menghadapi era distrupsi digital. `,
      listDeskripsi: [
        {
          subTitle: `Program studi akuntansi merupakan program studi unggulan yang hadir dan dapat beradaptasi di tengah persainagn dalam dunia usaha dan dunia industri (DUDI). `,
          descTitle: [` Mampu beradaptasi dalam perkembangan zaman teknologi dan informasi program studi ini memiliki keunggulan dari program studi sejenis dalam menjalankan tri dharma perguruan tingi  dan langsung dapat diimplementasikan dari program pembelajaran yang telah diperoleh dan memiliki tanggung jawab moril dalam pekerjaan dengan professional serta ahli dalam bidangnya . 
          `],
        },
      ],
      karir: `Karir pada bidang akuntansi:`,
      listKarir: [
        `Karir`,
        `Penyaji Informasi Akuntansi`,
        `Pemeriksa Informasi Akuntansi`,
        `Pelaksana Perpajakan`,
        `Akuntan Publik/Swasta`,
        `Akuntan Profesi`,
        `Akuntan Pendidik`,
        `Konsultan`,
        `Wirausaha`,
      ],
      kurikulum: kurikulumAkuntansi,
    },
  },
  {
    img: hukum,
    subject: "Hukum",
    link: "/program/hukum",
    details: {
      desc: `Prodi Ilmu Hukum merupakan salah satu prodi yang berada di bawah naungan Universitas Almarisah Madani. Prodi Ilmu Hukum Bertujuan Menghasilkan lulusan di bidang hukum yang profesional, berintegritas tinggi, peduli pada kepentingan masyarakat luas, dan berorientasi global, serta responsif terhadap kemajuan IPTEK di era digital.`,
      listDeskripsi: [
        {
          subTitle: `Penegak Hukum`,
          descTitle: [`Lulusan memiliki kemampuan untuk menyelesaikan permasalahan hukum sesuai dengan lingkup pekerjaan dan profesinya menurut prinsip-prinsip hukum yang berkeadilan dengan kemampuan memanfaatkan teknologi informasi. Serta mampu menjalani tanggung jawab sebagai penegak hukum sesuai dengan etika profesinya.`],
        },
        {
          subTitle: `Konsultan Hukum`,
          descTitle: [`Lulusan memiliki kemampuan untuk menjalankan kegiatan usaha dibidang hukum dengan kemampuan memanfaatkan teknologi informasi yang menghasilkan keuntungan dan menanggung resiko yang akan terjadi dalam kegiatan usahanya.`],
        },
        {
          subTitle: `Perancang Perundang-undangan dan Kontrak`,
          descTitle: [`Lulusan memiliki kemampuan untuk merancang naskah hukum / kontrak atau MoU dengan memanfaatkan teknologi informasi.
          `],
        },
      ],
      karir: `Karir: `,
      listKarir: [
        `Penegak Hukum	: Advokat, Panitera, Jaksa, dan Hakim.`,
        `Tim Legal	: Konsultan Hukum, Perancang Perundang-Undangan, Perancang Kontrak, Diplomat, Mediator, dan Notaris
      `,
      ],
      kurikulum: kurikulumHukum,
    },
  },
  {
    img: sisteminformasi,
    subject: "Sistem Informasi",
    link: "/program/sistem-informasi",
    details: {
      desc: `Dalam rangka menyiapkan mahasiswa menghadapi perubahan sosial, budaya, dunia kerja dan kemajuan teknologi yang pesat, kompetensi mahasiswa harus disiapkan untuk lebih gayut dengan kebutuhan zaman. Link and match tidak saja dengan dunia industri dan dunia kerja tetapi juga dengan masa depan yang berubah dengan cepat. Universitas Almarisah Madani dituntut untuk dapat merancang dan melaksanakan proses pembelajaran yang inovatif agar mahasiswa dapat meraih capaian pembelajaran yang mencakup aspek sikap, pengetahuan, dan keterampilan secara optimal dan selalu relevan.
      Profil lulusan dilengkapi dengan uraian ringkas kompetensi seluruh profil yang sesuai dengan program pendidikan sarjana, dan keterkaitan profil tersebut dengan keunggulan atau keunikan program studi.
      `,
      listDeskripsi: [
        {
          subTitle: `Programmer`,
          descTitle: [],
        },
        {
          subTitle: `System Analyst`,
          descTitle: [],
        },
        {
          subTitle: `Database Administrator`,
          descTitle: [],
        },
        {
          subTitle: `Web Developer`,
          descTitle: [],
        },  
      ],
      karir: `Bentuk kegiatan pembelajaran Merdeka Belajar-Kampus Merdeka di Universitas Almarisah Madani, sesuai dengan Permendikbud No 3 Tahun 2020 Pasal 15 ayat 1, dapat dilakukan di dalam Program Studi dan di luar Program Studi meliputi 8 jenis  kegiatan seperti :
      `,
      listKarir: [
        `Pengabdian kepada Masyarakat`,
        `Proyek Kemanusiaan`,
        `Asistensi Mengajar di Satuan Pendidikan`,
        `Penelitian/Riset`,
        `Magang/Praktik Kerja`,
        `Kegiatan Wirausaha`,
        `Pertukaran Mahasiswa`,
        `Studi/Proyek`,
      ],
      kurikulum: kurikulumInformasi,
    },
  },
  {
    img: informatika,
    subject: "Informatika",
    link: "/program/informatika",
    details: {
      desc: `Program Studi Teknik Informatika adalah program pendidikan tinggi yang bertujuan untuk mempersiapkan mahasiswa dalam memahami, menerapkan, dan mengembangkan teknologi informasi dan komputer.`,
      listDeskripsi: [
        {
          subTitle: `Pengetahuan Komputasi`,
          descTitle: [],
        },
        {
          subTitle: `Pengembangan Perangkat Lunak`,
          descTitle: [],
        },
        {
          subTitle: `Jaringan Komputer`,
          descTitle: [],
        },
        {
          subTitle: `Basis Data`,
          descTitle: [],
        },  
        {
          subTitle: `Kecerdasan Buatan`,
          descTitle: [],
        },
        {
          subTitle: `Keamanan Informasi`,
          descTitle: [],
        },
        {
          subTitle: `Analisis Data`,
          descTitle: [],
        },
      ],
      karir: `Program Studi Teknik Informatika memberikan landasan yang kuat dalam bidang teknologi informasi, komputasi, dan pengembangan perangkat lunak. Lulusan program ini memiliki peluang karir yang luas di berbagai sektor, termasuk pengembangan perangkat lunak, analisis data, keamanan informasi, konsultasi teknologi, dan wirausaha teknologi.
      `,
      listKarir: [
        `Pengembang Perangkat Lunak.`,
        `Arsitek Perangkat Lunak`,
        `Administrator Jaringan`,
        `Analis Keamanan Informasi`,
        `Data Scientist`,
        `Konsultan Teknologi`,
        `Pengembang Aplikasi Mobile`,
        `Peneliti Teknologi`,
        `Wirausaha Teknologi`,
      ],
      kurikulum: kurikulumInformatika,
    },
  },
  {
    img: ilmukomunikasi,
    subject: "Ilmu Komunikasi",
    link: "/program/ilmu-komunikasi",
    details: {
      desc: `Dirancang untuk menghasilkan lulusan yang terampil dalam komunikasi tertulis dan lisan, ditambah menguasai kompetensi di bidang komunikasi Kesehatan berbasis teknologi informasi sehingga dapat diterapkan di bidang-bidang  seperti media, hubungan masyarakat, hingga bisnis.
      `,
      listDeskripsi: [
        {
          subTitle: `Bidang Media`,
          descTitle: [],
        },
        {
          subTitle: `Fotografer, Videografer , editor, penyiar, reporter dan wartawan`,
          descTitle: [],
        },
        {
          subTitle: `Bidang hubungan masyarakat`,
          descTitle: [],
        },
        {
          subTitle: `Hubungan masyarakat, negosiator, master of ceremony, konsultan Kesehatan, spesialis komunikasi Kesehatan`,
          descTitle: [],
        },  
        {
          subTitle: `Strategi promosi, manajemen Kesehatan, konten creator, digital marketing`,
          descTitle: [],
        },
      ],
      karir: `Fasilitas yang diberikan untuk belajar dalam konsentrasi ini meliputi ruang akademik khusus, yaitu: Studio fotografi, studio radio, studio TV, Laboratorium Publik Speaking, Laboratorium Komputer, Laboratorium Branding & Communication Strategy.`,
      listKarir: [],
      kurikulum: kurikulumKomunikasi,
    },
  },
  {
    img: d3farmasi,
    subject: "D3 Farmasi",
    link: "/program/d3-farmasi",
    details: {
      desc: `Program Pendidikan vokasi kefarmasian jenjang Diploma Tiga (DIII) Farmasi merupakan bagian integral dari program pengembangan tenaga kesehatan untuk menghasilkan tenaga Ahli Madya Farmasi yang terampil dan profesional dalam bidang pelayanan kefarmasian, pengelolaan sediaan farmasi, alat kesehatan (Alkes), dan bahan medis habis pakai (BMHP), serta produksi sediaan farmasi.`,
      listDeskripsi: [  
        {
          subTitle: `Sumber Daya Manusia`,
          descTitle: [`Dalam pengembangan dan pengelolaan PS, diploma tiga didukung dengan jumlah SDM yang memadai. Dosen sebanyak 7 orang berkualifikasi magister (5 orang) dan Doktoral (2 orang), yang sebagian besar memiliki jabatan akademik lektor.`],
        },
        {
          subTitle: `Sarana dan Prasarana`,
          descTitle: [`Prasarana yang dimiliki meliputi, gedung perkuliahan (kelas), aula, ruang laboratorium (7 laboratorium), ruang rapat, kantor (BAAK/BAUK), ruang dosen, ruang ketua Yayasan, ruang pimpinan (Ketua STIFA dan wakilnya), ruang ketua PS, ruang LPMI, ruang LPPM, ruang CBT, ruang OSCE, ruang perpustakaan, kantin, musala, toilet, ruang dokumen, dapur, ruang klinik, apotek STIFA, area parkir, green house, ruang kandang hewan, pos security, dan lapangan olah raga.`],
        },
        {
          subTitle: `Kurikulum`,
          descTitle: [`Kurikulum Diploma Tiga Farmasi disusun berbasis pada standar kompetensi lulusan, terintegrasi, berdasarkan standar kompetensi yang ditetapkan oleh APDFI dan profil lulusan yang dirancang dari hasil tracer study dan input stakeholder. Kurikulum Diploma Tiga selalu up to date dengan melakukan peninjauan kurikulum secara berkala atau insidentil bila terdapat perubahan peraturan. Untuk penguatan konten kurikulum berbasis pelayanan dikembangkan dengan menambah mata kuliah seperti pengetahuan di bidang swamedikasi, nutrisi parenteral, dan perapotekan. Selain itu untuk mengaplikasikan keilmuan berbasis pelayanan, telah didirikan apotek dan klinik dengan dukungan, bimbingan, dan arahan dari Dokter dan Apoteker, sehingga mahasiswa dapat belajar secara langsung teori sekaligus praktiknya.`],
        },
        {
          subTitle: `Pendidikan`,
          descTitle: [`Dalam aspek Pendidikan kinerja UPPS dan PS dapat dilihat dari ketercapaian capaian pembelajaran yang diantaranya dipantau melalui angka kelulusan yang cukup tinggi selam 3 tahun terakhir yaitu > 85%, IPK > 3,0, persentase ketepatan masa studi > 50%, tingkat kepuasan pengguna lulusan dengan penilaian baik, dan rerata masa tunggu kerja yang singkat yaitu ≤ 3 bulan. Selain itu keberhasilan pendidikan juga ditinjau dari banyaknya perolehan prestasi dari mahasiswa baik di tingkat wilayah/provinsi, nasional maupun internasional.`],
        },
        {
          subTitle: `Kerja sama`,
          descTitle: [`Implementasi kepemimpinan publik juga diterapkan dengan menjalin kerja sama dengan sejumlah 46 mitra dari berbagai instansi (41 instansi dalam negeri dan 5 instansi luar negeri) meliputi RS, apotek, PBF, industri (farmasi, obat tradisonal, dan kosmetik), instansi pemerintah, perguruan tinggi dalam dan luar negeri, serta lembaga lainnya.`],
        },
        {
          subTitle: `Prestasi Mahasiswa`,
          descTitle: [`Data prestasi menunjukkan perolehan sebanyak 30 prestasi dari kegiatan akademik maupun non akademik yang terdiri dari 9 prestasi tingkat wilayah/provinsi, 18 prestasi tingkat nasional, dan 3 prestasi tingkat internasional. Kinerja dalam bidang penelitian dan pengabdian masyarakat yang dilakukan dosen STIFA Makassar juga menunjukkan keunggulan yang baik.`],
        },
      ],
      karir: `Lulusan D3 Farmasi dapat berkarier menjadi :`,
      listKarir: [
        `Pelaksana pelayanan kefarmasian`,
        `Pelaksana Pengelolaan`,
        `Sediaan Farmasi, Alat Kesehatan (Alkes), dan Bahan Medis Habis Pakai (BMHP)`,
        `Pelaksana Produksi Sediaan Farmasi`,
      ],
      kurikulum: kurikulumd3farmasi,
    },
  },
  {
    img: d3kebidanan,
    subject: "D3 Kebidanan",
    link: "/program/d3-kebidanan",
    details: {
      desc: `Kebidanan D3 dalam proses belajar mengajar bertujuan untuk menghasilkan lulusan yang berperan sebagai Provider Care (pemberi asuhan) Kebidanan yang berakhlak mulia, kompetitif, unggul dalam pelayanan kesehatan balita dan anak pra sekolah.
      `,
      listDeskripsi: [
        {
          subTitle: 'Keunggulan Prodi',
          descTitle: [`Akademi Kebidanan Universitas Almarisah Madani  dalam belajar mengajar bertujuan untuk menghasilkan lulusan yang berperan sebagai Provider Care (pemberi asuhan) kebidanan yang berakhlak mulia, kompetitif, unggul dalam pelayanan kesehatan balita dan anak pra sekolah), Lulusan menjadi Bidan yang bekerja di sektor pemerintahan, swasta maupun luar negeri juga dapat membuka usaha Praktik Mandiri Bidan, usaha Mom’s treatment,Baby Message & SPA atau meditasi persalinan.`],
        },
        {
          subTitle: `Visi`,
          descTitle: [`Terwujudnya pendidikan tinggi Vokasi yang menghasilkan lulusan Bidan yang berakhlak mulia, kompetitif dalam pelayanan kebidanan sesuai kewenangan, unggul dalam pelayanan kesehatan balita dan anak prasekolah dan  tingkat nasional tahun 2025. `],
        },
        {
          subTitle: `Misi`,
          descTitle: [`Menyelenggarakan dan mengembangkan pendidikan tinggi Vokasi untuk menghasilkan bidan yang kompeten dalam pelayanan kebidanan  dan unggul dalam pelayanan kesehatan balita dan anak prasekolah.`,
                      `Mengembangkan penelitian terapan kebidanan yang mengacu pada pelayanan kebidanan, pelayanan kesehatan balita dan anak prasekolah` ,
                      `Menyelenggarakan pengabdian masyarakat dalam bidang pelayanan kebidanan berkelanjutan serta pendampingan pada keluarga melalui pelayanan kesehatan balita dan anak prasekolah.`,
                      `Menyelenggarakan tata kelola program studi secara mandiri,  efektif efisien melalui iklim belajar yang nyaman, harmonis, saling bekerjasama dan saling menghargai antar svitas akademika`,
                      ` Menjalin kerjasama dengan berbagai instansi kesehatan baik negeri maupun swasta serta mitra kerja terkait lainnya sebagai bagian dari pengembangan Prodi D III Kebidanan Universitas Almarisah Madani.`,
                      ],
        },
        {
          subTitle: `Tujuan`,
          descTitle: [`Tujuan pendidikan Diploma III Kebidanan Universitas Almarisah Madani adalah untuk menghasilkan Ahli Madya Kebidanan sebagai tenaga bidan pelaksana yang mampu melaksanakan tugas sesuai kewenangan dan kompetensinya.`],
        },
        {
          subTitle: `Sasaran`,
          descTitle:[`Menyelenggarakan dan mengembangkan pendidikan tinggi Vokasi untuk menghasilkan bidan yang kompeten dalam pelayanan kebidanan  dan unggul dalam pelayanan kesehatan balita dan anak prasekolah.`,
                    `Mengembangkan penelitian terapan kebidanan yang mengacu pada pelayanan kebidanan, pelayanan kesehatan balita dan anak prasekolah`,
                    `Menyelenggarakan pengabdian masyarakat dalam bidang pelayanan kebidanan berkelanjutan serta pendampingan pada keluarga melalui pelayanan kesehatan balita dan anak prasekolah.`,
                    `Menyelenggarakan tata kelola program studi secara mandiri,  efektif efisien melalui iklim belajar yang nyaman, harmonis, saling bekerjasama dan saling menghargai antar svitas akademika`,
                    `Menjalin kerjasama dengan berbagai instansi kesehatan baik negeri maupun swasta serta mitra kerja terkait lainnya sebagai bagian dari pengembangan Prodi D III Kebidanan Almarisah Madani`,
                    ],
        },
      ],
      karir: ``,
      listKarir: [],
      kurikulum: kurikulumd3kebidanan,
    },
  },
];

export const jalurSukses = [
  {
    image: jalursukses,
    title: "Start-up Business",
  },
  {
    image: jalursukses,
    title: "Start-up Business",
  },
  {
    image: jalursukses,
    title: "Start-up Business",
  },
];

export const partners = {
  universities: [
    ucsi,
    uistinye,
    umahsa,
    umara,
    umsu,
    usainsmy,
    utmu,
    utoyama,
    uzaim,
  ],
  insitutions: [
    aam1,
    aam2,
    biofarma,
    brivit1,
    brivit2,
    bsi,
    combiphar,
    dipa,
    herosupermarket,
    ismut,
    mensa,
    natura,
    penta,
    royal,
  ],
};

export const socialMedias = [
  {
    name: "facebook",
    image: PiFacebookLogoLight,
    link: "https://web.facebook.com/univeral.official",
  },
  {
    name: "twitter",
    image: PiTwitterLogoLight,
    link: "https://www.twitter.com",
  },
  {
    name: "instagram",
    image: PiInstagramLogoLight,
    link: "https://www.instagram.com/univeral.official/",
  },
  {
    name: "youtube",
    image: PiYoutubeLogoLight,
    link: "https://www.youtube.com/",
  },
  {
    name: "tiktok",
    image: PiTiktokLogoLight,
    link: "https://www.tiktok.com/@univeral.official",
  },
  {
    name: "whatsapp",
    image: PiWhatsappLogoLight,
    link: "https://api.whatsapp.com/send?phone=+62882019436805&text=Saya%20tertarik%20untuk%20mengetahui%20lebih%20banyak%20mengenai%20Univeral!",
  },
  {
    name: "telegram",
    image: PiTelegramLogoLight,
    link: "htttps://web.telegram.org/",
  },
];

export const footerLinks = [
  {
    name: "FAQ",
    link: "/faq",
  },
  {
    name: "Fasilitas",
    link: "/fasilitas",
  },
  {
    name: "Akomodasi",
    link: "/informasi-akomodasi",
  },
  {
    name: "Artikel",
    link: "/artikel",
  },
  {
    name: "Kegiatan & Acara",
    link: "/kegiatan-acara",
  },
];

// article card
export const articles = [
  {
    img: artikel1p1,
    title: "Penyerahan Surat Keputusan perubahan bentuk Sekolah Tinggi Ilmu Farmasi (STIFA) Makassar menjadi Universitas Almarisah Madani (Univeral) Makassar dan pelantikan Rektor Universitas Almarisah Madani periode 2023 - 2028",
    link: "/artikel/penyerahan-sk-perubahan-bentuk-sekolah-tinggi-ilmu-farmasi-stifa-makassar-menjadi-universitas-almari",
    details: {
      author: "Admin UNIVERAL",
      date: "18 September 2023",
      text: [
        `Sekolah Tinggi Ilmu Farmasi Makassar resmi berubah bentuk menjadi Universitas Almarisah Madani pada tanggal 28 Agustus 2023 ditandai dengan keluarnya Surat Keputusan Menteri Pendidikan, Kebudayaan, Riset, dan Teknologi Republik Indonesia tentang Izin penggabungan Sekolah Tinggi Ilmu Farmasi Makassar di Kota Makassar dan Akademi Kebidanan Minasa Upa di Kota Makassar menjadi Universitas Almarisah Madani di Kota Makassar Provinsi Sulawesi Selatan yang diselenggarakan oleh Yayasan Almarisah Madani. `,
        `Surat keputusan Perubahan Bentuk diserahkan langsung oleh Ketua LLDIKTI Wilayah IX, Drs.Andi Lukman kepada Ketua Yayasan Almarisah Madani, Drs. H. Sahibuddin A. Gani, apt. selaku Badan Penyelenggara pada tanggal 08 September 2023. Pada tanggal yang sama, Rektor pertama Universitas Almarisah Madani, Dr. Nursamsiar, M.Si dilantik untuk periode 2023-2028.
        `,
        `Dalam pidatonya, Rektor Universitas Almarisah Madani menyebutkan bahwa Universitas Almarisah  Madani  akan memberikan pendidikan yang relevan, inovatif, dan berorientasi pada nilai, serta memfasilitasi penelitian yang mendorong perkembangan ilmu pengetahuan dan masyarakat. Kami ingin berkolaborasi dengan komunitas lokal dan global untuk mengatasi tantangan-tantangan global dan mewujudkan visi kami bersama. 
        `,
      ]
    },
  },
  {
    img: artikel2p1,
    title: "Mahasiswa Universitas Almarisah Madani Meraih Gold Medal pada Ajang Indonesia International Invention",
    link: "/artikel/mahasiswa-universitas-almarisah-madani-meraih-gold-medal-pada-ajang-indonesia-international-inventio",
    details: {
      author: "Admin UNIVERAL",
      date: "18 September 2023",
      text: [
        `Mahasiswa Fakultas kesehatan Universitas Almarisah Madani meraih Gold Medal pada ajang Indonesia International Invention Expo 2023 yang di selenggarakan di Semarang pada tanggal 25-28 Agustus 2023.`,
        `Ibu  apt. Amriani Sapra, S.Farm., M.Si selaku Dosen Farmasi Universitas  Almarisah  Madani mendampingi tiga Mahasiswa yaitu Andi Daffa Alifah Abbas, Awwalindah Suarhap, dan Muhammad Fadly Noor Alwani untuk projek Cosmaceutical Cream Nanotechnology Innovation : M. calabura Extract-loaded Transfersome as Dermal Antiaging dalam ajang Indonesia International Invention Expo 2023 dan berhasil meraih Gold Medal serta Best Poster.`,
      ]
    },
  },
];

export const tuitonAccordion = [
  {
    key: 1,
    title: `Fakultas Kesehatan`,
    image: tuition1,
    isOpen: false,
  },
  {
    key: 2,
    title: "Fakultas Bisnis, Teknologi dan Sosial",
    image: tuition2,
    isOpen: false,
  },
];

export const faqAccordion = [
  {
    key: 1,
    title: `Apa itu Universitas Almarisah Madani?`,
    data: `UniversitasAlmarisah Madani adalah lembaga pendidikan tinggi yang berkomitmen untuk memberikan pendidikan berkualitas tinggi dan mempersiapkan mahasiswa untuk memasuki dunia profesional.`,
    isOpen: false,
  },
  {
    key: 2,
    title: `Di mana lokasi kampus Universitas Almarisah Madani?`,
    data: `Kampus Universitas Almarisah Madani terletak di Jl. Perintis Kemerdekaan Km 13,7, Paccerakkang, Biringkanaya, Makassar Sulawesi Selatan dengan fasilitas-fasilitas modern dan aksesibilitas yang baik.`,
    isOpen: false,
  },
  {
    key: 3,
    title: `Apa program studi yang tersedia di Universitas Almarisah Madani?`,
    data: `Universitas Almarisah Madani menawarkan berbagai program studi, yaitu :`,
    list: [
      `a.	D3 Kebidanan`,
      `b.	D3 Farmasi`,
      `c.	S1 Farmasi`,
      `d.	Profesi Apoteker`,
      `e.	S1 Manajemen`,
      `f.	S1 Akuntansi`,
      `g.	S1 Ilmu Hukum`,
      `h.	S1 Ilmu Komunikasi`,
      `i.	S1 Sistem Informasi`,
      `j.	S1 Informatika. Untuk informasi`,
      `lebih lanjut, silakan kunjungi halaman www.univeral.ac.id`,
    ],
    isOpen: false,
  },
  {
    key: 4,
    title: `Bagaimana cara mendaftar di Universitas Almarisah Madani?`,
    data: `Untuk mendaftar di Universitas Almarisah Madani, Anda dapat mengunjungi halaman penerimaan di situs web resmi kami www.pmb.univeral.ic.id . Di sana Anda akan menemukan panduan langkah demi langkah tentang proses aplikasi.`,
    isOpen: false,
  },
  {
    key: 5,
    title: `Kapan tanggal-tanggal penting untuk penerimaan mahasiswa baru?`,
    data: `PMB Universitas Almarisah Madani dibuka dari tanggal 14 September 2023 sampai 14 Oktober 2023. Info lebih lanjut bisa mengunjungi web pmb www.pmb.univeral.ac.id.`,
    isOpen: false,
  },
  {
    key: 6,
    title: `Berapa biaya kuliah di Universitas?`,
    data: `Biaya kuliah di Universitas Almarisah Madani bervariasi tergantung pada program studi dan status keanggotaan Anda. Untuk informasi lebih lanjut, silakan kunjungi halaman biaya kuliah kami di www.pmb.univeral.ac.id`,
    isOpen: false,
  },
  {
    key: 7,
    title: `Apa fasilitas tambahan yang tersedia di kampus?`,
    data: `Kampus Universitas Almarisah Madani dilengkapi dengan berbagai fasilitas seperti perpustakaan, laboratorium, Klinik, Apotek, dan masih banyak lagi. Untuk rincian lengkap, silakan kunjungi halaman fasilitas kami. Selain itu Mahasiwa juga dilengkapi dengan KTM yang dapat memudahkan Mahasiswa dalam bertransaksi.`,
    isOpen: false,
  },
  {
    key: 8,
    title: `Apa kegiatan ekstrakurikuler yang tersedia untuk mahasiswa?`,
    data: `Universitas Almarisah Madani menawarkan berbagai  organisasi, dan kegiatan ekstrakurikuler. Mahasiswa dapat bergabung dengan klub yang sesuai dengan minat mereka.Ekstrakurikuler yang dimaksud seperti Badminton, Volly, Tenis, Futsa, Tari dan masih banyak lagi.`,
    isOpen: false,
  },
  {
    key: 9,
    title: `Bagaimana cara mahasiswa mengakses layanan kesehatan di kampus?`,
    data: `Layanan kesehatan tersedia di kampus Universitas Almarisah Madani melalui Klinik yang tersedia di kampus.`,
    isOpen: false,
  },

];

export const facilities = [
  {
    title: "Fasilitas",
    img: fac1,
  },
  {
    title: "Fasilitas",
    img: fac2,
  },
  {
    title: "Fasilitas",
    img: fac3,
  },
  {
    title: "Fasilitas",
    img: fac4,
  },
  {
    title: "Fasilitas",
    img: fac5,
  },
  {
    title: "Fasilitas",
    img: fac6,
  },
  {
    title: "Fasilitas",
    img: fac7,
  },
  {
    title: "Fasilitas",
    img: fac8,
  },
];
export const events = [
  {
    img: landingheader1,
    title: "EVENT NAME",
    date: "18 September 2023",
    time: "10.00 - 12.00",
  },
];

export const achievement = [
  {
    img: penghargaan1,
    title:
      "GOLD MEDAL DAN BEST POSTER INDONESIA INTERNASIONAL INVENTION EXPO 2023",
    path: "/",
  },
];

export const beasiswa = [
  {
    img: beasiswa1,
    title: "Beasiswa Jalur Undangan Prestasi",
    path: "/",
  },
  {
    img: beasiswa2,
    title: "Beasiswa Anak Pengajar",
    path: "",
  },
  {
    img: beasiswa3,
    title: "Beasiswa Basket",
    path: "",
  },
  {
    img: beasiswa4,
    title: "Beasiswa Sekolah Kerjasama",
    path: "",
  },
  {
    img: beasiswa5,
    title: "Beasiswa Alumni",
    path: "",
  },
  {
    img: beasiswa6,
    title: "Beasiswa Prestasi Khusus",
    path: "",
  },
  {
    img: beasiswa7,
    title: "Beasiswa MPK / OSIS",
    path: "",
  },
  {
    img: beasiswa8,
    title: "Beasiswa Anak Rohaniawan",
    path: "",
  },
  {
    img: beasiswa9,
    title: "Beasiswa Sosial Ekonomi",
    path: "",
  },
];

export const pmb = [
  {
    img: pmb1,
    title: "Alur Pendaftaran",
    path: "/",
  },
  {
    img: pmb2,
    title: "Beli Kode Voucher",
    path: "",
  },
  {
    img: pmb3,
    title: "Daftar Sekarang",
    path: "",
  },
  {
    img: pmb4,
    title: "Jadwal Tes Seleksi",
    path: "",
  },
  {
    img: pmb5,
    title: "Login untuk Cek Status",
    path: "",
  },
  {
    img: pmb6,
    title: "Buku Panduan PMB",
    path: "",
  },
  {
    img: pmb7,
    title: "Pindah Pilihan Program",
    path: "",
  },
  {
    img: pmb8,
    title: "Registrasi Ulang",
    path: "",
  },
  {
    img: pmb9,
    title: "Biaya Kuliah",
    path: "",
  },
  {
    img: pmb10,
    title: "Beasiswa",
    path: "",
  },
  {
    img: pmb11,
    title: "Pendaftaran bagi WNA",
    path: "",
  },
];

export const lowonganKerja = [
  {
    img: logo,
    title: "Dosen Teknik Informatika",
    data: "Penempatan di Makassar",
  },
  {
    img: logo,
    title: "Dosen Teknik Informatika",
    data: "Penempatan di Makassar",
  },
  {
    img: logo,
    title: "Dosen Teknik Informatika",
    data: "Penempatan di Makassar",
  },
];

// Leturers Profile
export const lecturersProfile = [
  {
    id: 1,
    photo: rektorProfile,
    name: "DR. Nursamsiar. M.Si.",
    position: "Rektor Universitas Almarisah Madani",
  },
  {
    id: 2,
    photo: wr1,
    name: "apt. Zulham, S.Farm, M.Si",
    position: "Wakil Rektor I Bidang Akademik dan Kemahasiswaan",
  },
  {
    id: 200,
    photo: lecturer,
    name: "Asty Almaida, SE.,M.Si",
    position: "Wakil Rektor II Bidang Sumber Daya Manusia dan Sarana Prasarana",
  },
  {
    id: 2,
    photo: wr3,
    name: "apt. Besse Hardianti, S.Si, M.Pharm., Sc., Ph.D",
    position: "Wakil Rektor III Bidang Riset, Inovasi dan Pengembangan",
  },
  {
    id: 3,
    photo: dekanfk,
    name: "Dr. apt. Fajriansyah, S.Farm., M.Si",
    position: "Dekan Fakultas Kesehatan",
  },
  {
    id: 3,
    photo: dekanfbts,
    name: "Alamsyah Yasir, SE., M.M",
    position: "Dekan Fakultas Bisnis, Teknologi dan Sosial",
  },
  {
    id: 4,
    photo: lembagaPPM,
    name: "Dr. apt. Nur Khairi, S.Si., M.Si",
    position:
      "Ketua Lembaga Penelitian dan Pengabdian Masyarakat (LPPM) Universitas Almarisah Madani",
  },
  {
    id: 4,
    photo: lembagaPMI,
    name: "Dr. apt. Maulita Indrisari, S.Si., M.Si",
    position:
      "Ketua Lembaga Penjaminan Mutu (LPMI) Universitas Almarisah Madani",
  },
  {
    id: 5,
    photo: kaprodiPSPPA,
    name: "Dr. apt. Budiman Yasir, S.Si",
    position: "Ka Prodi Profesi Apoteker",
  },
  {
    id: 5,
    photo: kaprodiS1Farmasi,
    name: "apt. Nurul Arfiyanti Yusuf, S.Farm., M.Si",
    position: "Ka Prodi S1 Farmasi",
  },
  {
    id: 5,
    photo: kaprodiD3Farmasi,
    name: "Dr. apt. Abd. Halim Umar, S.Farm., M.Si",
    position: "Ka Prodi D3 Farmasi",
  },
  {
    id: 5,
    photo: kaprodiD3Kebidanan,
    name: "Sukmawati, SST., M.Kes",
    position: "Ka Prodi D3 Kebidanan",
  },
  {
    id: 5,
    photo: kaprodiManajemen,
    name: "Abdul Razak J. Sabara, S.E., M.M",
    position: "Ka Prodi Manajemen",
  },
  {
    id: 5,
    photo: kaprodiAkuntansi,
    name: "Rahadian Cahyadi, S.E., M.Ak.",
    position: "Ka Prodi Akuntansi",
  },
  {
    id: 5,
    photo: kaprodiSisfor,
    name: "Andi Ikmal Rachman, S.Kom., M.M",
    position: "Ka Prodi Sistem Informasi",
  },
  {
    id: 5,
    photo: kaprodiInfor,
    name: "Siska Armalivia, S.Kom., M.T",
    position: "Ka Prodi Informatika",
  },
  {
    id: 5,
    photo: kaprodiIlkom,
    name: "Muhammad Taslim, S.I.Kom., M.I.Kom",
    position: "Ka Prodi Ilmu Komunikasi",
  },
  {
    id: 5,
    photo: kaprodiIlmuHukum,
    name: "Syawal Amirul Syah, S.H.,M.H",
    position: "Ka Prodi Hukum",
  },
  {
    id: 6,
    photo: ketuaGugusFK,
    name: "apt. Andi Ardiansyah Arifin, S.Si., M.Sc",
    position: "Ketua Gugus Penjaminan Mutu Fakultas Ilmu Kesehatan",
  },
  {
    id: 6,
    photo: ketuaGugusFBTS,
    name: "Andi Adam Maulana Yusvan, S.E., M.S.M",
    position:
      "Ketua Gugus Penjaminan Mutu Fakultas Bisnis, Teknologi, dan Sosial",
  },
  {
    id: 7,
    photo: kuppmFK,
    name: "apt. Lukman, S.Si., M.Farm",
    position:
      "Ketua Unit Penelitian dan Pengabdian Masyarakat Fakultas Ilmu Kesehatan",
  },
  {
    id: 7,
    photo: kuppmFBTS,
    name: "Thiara Tri Funny Manguma S.Kom., M.T",
    position:
      "Ketua Unit Penelitian dan Pengabdian Masyarakat Fakultas Bisnis, Teknologi, dan Sosial",
  },
];

export const LecProfileTableData = [
  {
    name: "Dr. apt. Wahyu Hendrarti, S.SI., M.Kes.",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Dr. Nursamsiar, M.Si",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Dr. apt. Maulita Indrisari, S.Si., M.Si.",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "apt. Besse Hardianti, S.Si., M.Pharm., Sc.",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Dr. apt. Nur Khairi, S.Si., M.Si.",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Reny Syahruni, S.Farm., M.Sc",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Michrun Nisa, S.Farm., M.Sc",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Dr. Abd. Halim Umar, S.Farm., M.Si",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Syamsu Nur, S.Farm., M.Sc., Apt",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Dr. Fajriansyah, S.Farm., M.Si., Apt",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "apt. Radhia Riski, S.Si., M.Si.",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Dr. Fitriyanti Jumaetri Sami, S.Si., M.Si",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "apt. Nurul Arfiyanti Yusuf, S.Farm., M.Si.",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Astuti, S.Si., M.Si",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "apt. Maria Ulfa, S.Farm., M.Si.",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Sitti Rahimah, S.Farm., M.Si., Apt",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Sukriani Kursia, S.Farm., M.Kes",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Mirnawati Salampe, S.Si., Apt",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Rahmad Aksa, S.Si., M.Si., Apt",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Saldi Hapiwaty, S.Farm., M.Kes. Apt",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Hamdayani L.A., S.Si., M.SI., Apt",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Akbar Awaluddin, S.Si., M.Si., Apt",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Yuri Pratiwi Utami, S.Farm., M.Si., Apt",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Lukman, S.Si., Apt., M.Farm",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Asril Burhan, S.Farm., M.Si., Apt",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Khairuddin, S.Si., M.Si., Apt",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Megawati, S.Pd., M.Si",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Andi Nur Aisyah, S.Si., M.Si., Apt",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Zulham, S.Farm., M.Si., Apt",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Fadillah Maryam B.A., S.Farm., M.Si., Apt",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Ismail, S.Farm., M.Sc",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Imrawati, S.Si., M.Si., Apt",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Fhahri Mubarak, S.Farm., M.Si",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Suwahyuni Mus, S.Si., M.Kes., Apt",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Marwati, S.Farm., M.Si.",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "apt. Amriani Sapra, S.Farm., M.Si",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "apt. Andi Paluseri, SE,  S.Farm, M.Kes",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "apt. Noer Fauziah Rahman, S.Farm, M.S.Farm",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Prof. Dr. M. Natsir Djide, MS, Apt",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "apt. Tuti Handayani Zainal, S.Farm, M.Si",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "apt. Nurzadrina Wahyuddin, S.Farm, M.Si",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "apt. Muh. Azwar AR, S.Si., M.Si",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "apt. Dewi Purwaningsih, S.Si, M.S.Farm",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Nurul Hikma, S.Farm, M.Si",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "apt. Nur Rahma Rumata, S.Farm, M.Si",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Rukman Muslimin",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Dr. apt. Budiman Yasir., S.Si",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Burhanuddin",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Muh. Syahrul",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "apt. Andi Ardiansyah Arifin, S.Si, M.Sc",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Ikbal, S.Farm., M.Si",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "dr. Sujud Zainur Rasyid, S.Ked, M.Si",
    studyProg: "Farmasi",
    faculty: "fk",
  },

  // AKBID
  {
    name: "Ary Handayani, SST, M. Kes",
    studyProg: "Kebidanan",
    faculty: "fk",
  },
  {
    name: "Sukmawati, SST, M. Kes",
    studyProg: "Kebidanan",
    faculty: "fk",
  },
  {
    name: "Husnul Hatima, SST., M. Kes",
    studyProg: "Kebidanan",
    faculty: "fk",
  },
  {
    name: "Nirwana, SST., M. Keb",
    studyProg: "Kebidanan",
    faculty: "fk",
  },
  {
    name: "Nurul Fitrahminarsih, SST., M. Keb",
    studyProg: "Kebidanan",
    faculty: "fk",
  },
  {
    name: "Endang Sulistyowati, SKM, M. Kes",
    studyProg: "Kebidanan",
    faculty: "fk",
  },
  {
    name: "Hafsah, ST., M. Kes",
    studyProg: "Kebidanan",
    faculty: "fk",
  },

  // FBTS

  // Akuntansi
  {
    name: "Nur Asmi Ainun Kamal, S.E., M.Ak",
    studyProg: "Akuntansi",
    faculty: "fbts",
  },
  {
    name: "Rahadian Cahyadi, S.E., M.Ak.",
    studyProg: "Akuntansi",
    faculty: "fbts",
  },
  {
    name: "Fia Fauzia Burhanuddin, B.Bus., M.Ak",
    studyProg: "Akuntansi",
    faculty: "fbts",
  },
  {
    name: "Melsa Jumliana, S.E.,M.Ak",
    studyProg: "Akuntansi",
    faculty: "fbts",
  },
  {
    name: "Fika Hartina Sari, A. Md, S.E.,M.Ak",
    studyProg: "Akuntansi",
    faculty: "fbts",
  },
  {
    name: "Sitti Jam'iah H.A, S.E., M.Ak",
    studyProg: "Akuntansi",
    faculty: "fbts",
  },

  // Manajemen
  {
    name: "Alamsyah Yasir, S.E., M.M",
    studyProg: "Manajemen",
    faculty: "fbts",
  },
  {
    name: "Abdul Razak J. Sabara, S.E., M.M",
    studyProg: "Manajemen",
    faculty: "fbts",
  },
  {
    name: "Andi Adam Maulana Yusvan, S.E., M.S.M",
    studyProg: "Manajemen",
    faculty: "fbts",
  },
  {
    name: "Basso Yusril, S.E., M.M",
    studyProg: "Manajemen",
    faculty: "fbts",
  },
  {
    name: "Jessie Alisa Rano S.M., M.M",
    studyProg: "Manajemen",
    faculty: "fbts",
  },

  // Hukum
  {
    name: "Sri Hasrina, S.H.,M.H",
    studyProg: "Hukum",
    faculty: "fbts",
  },
  {
    name: "Asrullah Dimas, S.H., M.H",
    studyProg: "Hukum",
    faculty: "fbts",
  },
  {
    name: "Inda Sari Palinrungi, S.H.,M.H",
    studyProg: "Hukum",
    faculty: "fbts",
  },
  {
    name: "Mirnayanti, S.H.,M.H",
    studyProg: "Hukum",
    faculty: "fbts",
  },
  {
    name: "Syawal Amirul Syah, S.H.,M.H",
    studyProg: "Hukum",
    faculty: "fbts",
  },

  // Sistem Informasi
  {
    name: "Andi Ikmal Rachman, S.Kom., M.M",
    studyProg: "Sistem Informasi",
    faculty: "fbts",
  },
  {
    name: "M. Rudini Kurniawan Amiruddin, S.ST., M.T",
    studyProg: "Sistem Informasi",
    faculty: "fbts",
  },

  // Ilmu Komunikasi
  {
    name: "Dea Audia Elsaid, S.I.Kom., M.I.Kom",
    studyProg: "Ilmu Komunikasi",
    faculty: "fbts",
  },
  {
    name: "Nur Istiqamah Desiana, S.Sos., M.I.Kom",
    studyProg: "Ilmu Komunikasi",
    faculty: "fbts",
  },
  {
    name: "Emil Fatra, S.I.Kom., M.I.Kom",
    studyProg: "Ilmu Komunikasi",
    faculty: "fbts",
  },
  {
    name: "Muhammad Taslim, S.I.Kom., M.I.Kom",
    studyProg: "Ilmu Komunikasi",
    faculty: "fbts",
  },
  {
    name: "Muh Nurqadri Jamal, S.Sos., M.I.Kom",
    studyProg: "Ilmu Komunikasi",
    faculty: "fbts",
  },
  {
    name: "Suherli, S.I.Kom., M.I.Kom",
    studyProg: "Ilmu Komunikasi",
    faculty: "fbts",
  },

  // Informatika
  {
    name: "Siska Armalivia, S.Kom., M.T",
    studyProg: "Informatika",
    faculty: "fbts",
  },
  {
    name: "Thiara Tri Funny Manguma S.Kom., M.T",
    studyProg: "Informatika",
    faculty: "fbts",
  },
  {
    name: "Muhammad Abdillah Rahmat, S.T., M.T",
    studyProg: "Informatika",
    faculty: "fbts",
  },
];

export const LecProfileTableCol = [
  {
    Header: "ID",
    accessor: "id",
    width: 6,
  },
  {
    Header: "Nama",
    accessor: "name",
  },
  {
    Header: "Prodi",
    accessor: "studyProg",
  },
];
export const textArtikel = [
  {
    id: 1,
    author: "admin",
    date: "18 September 2023",
    img: artikel1p1,
    title: "Mahasiswa Universitas Almarisah Madani Meraih Gold Medal pada Ajang Indonesia International Invention",
    text: [
      `Sekolah Tinggi Ilmu Farmasi Makassar resmi berubah bentuk menjadi Universitas Almarisah Madani pada tanggal 28 Agustus 2023 ditandai dengan keluarnya Surat Keputusan Menteri Pendidikan, Kebudayaan, Riset, dan Teknologi Republik Indonesia tentang Izin penggabungan Sekolah Tinggi Ilmu Farmasi Makassar di Kota Makassar dan Akademi Kebidanan Minasa Upa di Kota Makassar menjadi Universitas Almarisah Madani di Kota Makassar Provinsi Sulawesi Selatan yang diselenggarakan oleh Yayasan Almarisah Madani. `,
      `Surat keputusan Perubahan Bentuk diserahkan langsung oleh Ketua LLDIKTI Wilayah IX, Drs.Andi Lukman kepada Ketua Yayasan Almarisah Madani, Drs. H. Sahibuddin A. Gani, apt. selaku Badan Penyelenggara pada tanggal 08 September 2023. Pada tanggal yang sama, Rektor pertama Universitas Almarisah Madani, Dr. Nursamsiar, M.Si dilantik untuk periode 2023-2028.
      `,
      `Dalam pidatonya, Rektor Universitas Almarisah Madani menyebutkan bahwa Universitas Almarisah  Madani  akan memberikan pendidikan yang relevan, inovatif, dan berorientasi pada nilai, serta memfasilitasi penelitian yang mendorong perkembangan ilmu pengetahuan dan masyarakat. Kami ingin berkolaborasi dengan komunitas lokal dan global untuk mengatasi tantangan-tantangan global dan mewujudkan visi kami bersama. 
      `,
    ]
  }
]
