import { FaChevronRight } from "react-icons/fa";
import { Navbar, Header, Footer, Button } from "../components";
import { styles } from "../styles";
const KontakPage = () => {
  return (
    <>
      <Navbar />
      <Header title="Kontak" />
      <section className={`${styles.paddingX} py-10`}>
        <div className="grid w-full place-items-center">
          <form action="" className="max-w-[1152px] w-full flex flex-col">
            <label
              htmlFor="nama"
              className={`mb-2 text-primary-40 ${styles.headingS}`}
            >
              Nama Lengkap
            </label>
            <input
              type="text"
              className="px-4 py-3 mb-6 border focus:outline-0 border-neutral-70 placeholder:text-neutral-80 placeholder:"
              placeholder="Nama Lengkap"
              id="nama"
            />
            <label
              htmlFor="email"
              className={`mb-2 text-primary-40 ${styles.headingS}`}
            >
              Email
            </label>
            <input
              type="email"
              className="px-4 py-3 mb-6 border focus:outline-0 border-neutral-70 placeholder:text-neutral-80 placeholder:"
              placeholder="Email"
              id="email"
            />
            <label
              htmlFor="tel"
              className={`mb-2 text-primary-40 ${styles.headingS}`}
            >
              Nomor Telepon
            </label>
            <input
              type="tel"
              className="px-4 py-3 mb-6 border focus:outline-0 border-neutral-70 placeholder:text-neutral-80 placeholder:"
              placeholder="Nomor Telepon"
              id="tel"
            />
            <label
              htmlFor="pertanyaan"
              className={`mb-2 text-primary-40 ${styles.headingS}`}
            >
              Tuliskan Pertanyaan Anda
            </label>
            <textarea
              type="text"
              className="px-4 py-3 mb-6 h-[240px] border focus:outline-0 border-neutral-70 placeholder:text-neutral-80 resize-none"
              placeholder="Apa yang ingin Anda tanyakan?"
              id="Pertanyaan"
            />
            <div className="flex items-center justify-center w-full">
              <Button
                title="Kirim pertanyaan"
                icon={<FaChevronRight />}
                customization="text-neutral-10 bg-primary-40"
                type="submit"
                onClick={(evt) => evt.preventDefault() }
              />
            </div>
          </form>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default KontakPage;
