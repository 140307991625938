import { Navbar, Header, Footer } from "../components";
import { facilities } from "../constants";
import { styles } from "../styles";

const FasilitasPage = () => {
  return (
    <>
      <Navbar />
      <Header title="Fasilitas" />
      <main
        className={`${styles.paddingX} flex flex-col items-center gap-12 py-10`}
      >
        <h2 className={`${styles.headingM} text-center`}>
          Fasilitas-Fasilitas di Universitas Almarisah Madani
        </h2>
        <p>
          Untuk menunjang serta mewujudkan visi dan misi kampus, Universitas
          Almarisah Madani menyediakan fasilitas-fasilitas yang dapat membantu
          mencapai efisiensi dan efektivitas dalam pengelolaan sumber daya.
          Fasilitas-fasilitas tersebut diantaranya:
        </p>
        <section className="flex flex-wrap gap-16 smd:gap-6 justify-center w-full max-w-[1150px]">
          {facilities
            .filter((fac) => fac.title !== "vid")
            .map((fac) => {
              return (
                <div
                  style={{
                    background: `url(${fac.img}) center/cover no-repeat`,
                  }}
                  className="w-[380px] h-[238px] rounded-[10px] overflow-hidden relative"
                >
                  {/* <p className={`${styles.textLRegular} w-full bg-[#00000050] text-white font-semibold text-center absolute bottom-0 py-1`}>{fac.title}</p> */}
                </div>
              );
            })}
        </section>
      </main>

      <Footer />
    </>
  );
};

export default FasilitasPage;
