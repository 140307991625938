import { Header, Navbar, Footer } from "../components";
import { styles } from "../styles";
import { logoSejarah1, logo, logoAkfar, logoStifa, logoAkbidmu, logoYayasan, logoSejarah6 } from "../assets";

const SejarahPage = () => {
  return (
    <>
      <Navbar />
      <Header title="Rentang Sejarah" />

      <main className={`${styles.paddingArticle} flex flex-col py-14 items-center`}>
        <div className="max-w-[750px] flex flex-col items-center">
          
          <h1 className={`${styles.headingLM}  text-primary-40`}>Rentang Sejarah</h1>
          <h2 className={`${styles.headingSM} text-center`}>Moment Penting dalam Perjalanan Kampus</h2>
          
          {/* card */}
          <div className="flex flex-col items-center gap-8 pt-14">
          
          {/* card1 */}          
          <div className="relative flex items-center w-full gap-8 p-5 mt-5 border-2 border-neutral-50 sm:flex-col-reverse">
            <div className="my-1 border w-[20%] h-[42px] bg-primary-40 absolute top-[-21px] left-5 flex items-center flex-col justify-center">
              <p className={`${styles.headingMS}  text-neutral-10 text-center`}>1998</p>
            </div>
            
            <p className={`${styles.textLRegularM} h-fit`}>Berawal dari keprihatinan menyangkut kualitas dan keterampilan generasi muda, beberapa tokoh dibidang pendidikan dan kesehatan bersepakat untuk membentuk Yayasan Pendidikan Kebangsaan yang kelak akan menyelenggarakan beberapa Lembaga Pendidikan Tinggi dibidang kesehatan</p>

            <img className="w-[190px] sm:w-[150px]" src={logoSejarah1} />
          </div>
          
          {/* card2 */}
          <div className="relative flex items-center w-full gap-8 p-5 mt-5 border-2 border-neutral-50 sm:flex-col">
            <div className="my-1 border w-[20%] h-[42px] bg-primary-40 absolute top-[-21px] right-5 flex items-center flex-col justify-center">
              <p className={`${styles.headingMS}  text-neutral-10 text-center`}>1999</p>
            </div>
            
            <img className="w-[190px] sm:w-[150px]" src={logoAkfar} />
            
            <p className={`${styles.textLRegularM} h-fit`}>Dalam waktu yang cukup singkat gagasan Pendirian Akademi Farmasi Kebangsaan dapat terwujud dengan didapatkannya izin penyelenggaraan pendidikan Diploma III Farmasi dari Kementrian Kesehatan yang dikemudian hari dialihkan ke Kementrian Pendidikan dan Kebudayaan Republik Indonesia.</p>
          </div>
          
          {/* card3 */}
          <div className="relative flex items-center w-full gap-8 p-5 mt-5 border-2 border-neutral-50 sm:flex-col-reverse">
            <div className="my-1 border w-[20%] h-[42px] bg-primary-40 absolute top-[-21px] left-5 flex items-center flex-col justify-center">
              <p className={`${styles.headingMS}  text-neutral-10 text-center`}>2004</p>
            </div>
            
            <p className={`${styles.textLRegularM} h-fit`}>Tingginya partisipasi dan minat peserta didik akan keberlangsungan dan keberlanjutan pendidikan di bidang Farmasi mendorong dibukanya Sekolah Tinggi Ilmu Farmasi Makassar yang menyelenggarakan pendidikan Sarjana Farmasi S1 yang kelak dikemudian hari juga bertambah dengan Program Profesi Apoteker</p>

            <img className="w-[190px] sm:w-[150px]" src={logoStifa} />
          </div>
          
          {/* card4 */}
          <div className="relative flex items-center w-full gap-8 p-5 mt-5 border-2 border-neutral-50 sm:flex-col">
            <div className="my-1 border w-[20%] h-[42px] bg-primary-40 absolute top-[-21px] right-5 flex items-center flex-col justify-center">
              <p className={`${styles.headingMS}  text-neutral-10 text-center`}>2006</p>
            </div>
            
            <img className="w-[190px] sm:w-[150px]" src={logoAkbidmu} />
            
            <p className={`${styles.textLRegularM} h-fit`}>Dalam lingkup bidang kesehatan lainnya, turut berkembang pula minat dan partisipasi peserta didik untuk melanjutkan pendidikan ilmu kebidanan pada Akademi Kebidanan Minasa Upa yang menyelenggarakan pendidikan Diploma III Kebidanan dari Kementrian Pendidikan Nasional Republik Indonesia</p>
          </div>
          
          {/* card5 */}
          <div className="relative flex items-center w-full gap-8 p-5 mt-5 border-2 border-neutral-50 sm:flex-col-reverse">
            <div className="my-1 border w-[20%] h-[42px] bg-primary-40 absolute top-[-21px] left-5 flex items-center flex-col justify-center">
              <p className={`${styles.headingMS}  text-neutral-10 text-center`}>2012</p>
            </div>
            
            <p className={`${styles.textLRegularM} h-fit`}><b>Yayasan Almarisah Madani</b> hadir menggantikan peran Yayasan Pendidikan Kebangsaan sebagai Badan Penyelenggara Akademi Farmasi Kebangsaan dan Sekolah Tinggi Ilmu Farmasi Makassar untuk mewujudkan amanah dari Undang Undang No.16 Tahun 2001 tentang Yayasan</p>

            <img className="w-[190px] sm:w-[150px]" src={logoYayasan} />
          </div>
          
          {/* card6 */}
          <div className="relative flex items-center w-full gap-8 p-5 mt-5 border-2 border-neutral-50 sm:flex-col">
            <div className="my-1 border w-[20%] h-[42px] bg-primary-40 absolute top-[-21px] right-5 flex items-center flex-col justify-center">
              <p className={`${styles.headingMS}  text-neutral-10 text-center`}>2019</p>
            </div>
            
            <img className="w-[190px] sm:w-[150px]" src={logoSejarah6} />
            
            <p className={`${styles.textLRegularM} h-fit`}>Semakin dinamis dan berkembangnya Sistem Pendidikan Tinggi secara Nasional menjadikan dasar dilakukannya Penggabungan dan Peleburan  Akademi Farmasi Kebangsaan kedalam Sekolah Tinggi Ilmu Farmasi Makassar (STIFA) demi mewujudkan cita-cita menghadirkan Kampus yang Unggul .</p>
          </div>
          
          {/* card7 */}
          <div className="relative flex items-center w-full gap-8 p-5 mt-5 border-2 border-neutral-50 sm:flex-col-reverse">
            <div className="my-1 border w-[20%] h-[42px] bg-primary-40 absolute top-[-21px] left-5 flex items-center flex-col justify-center">
              <p className={`${styles.headingMS}  text-neutral-10 text-center`}>2023</p>
            </div>
            
            <p className={`${styles.textLRegularM} h-fit`}>Usaha mewujudkan Kampus Unggul tetap diupayakan kembali melalui Penggabungan Sekolah Tinggi Ilmu Farmasi Makassar dan Akademi Kebidanan Minasa Upa menjadi : <br></br> <b>Universitas Almarisah Madani</b></p>

            <img className="w-[190px] sm:w-[150px]" src={logo} />
          </div>

          </div>
        </div>
      </main>

      <Footer/>
    </>
  )
}

export default SejarahPage;