import { Navbar, Header, Footer } from "../components";

import { styles } from "../styles";
const TentangPage = () => {
  return (
    <>
      <Navbar />
      <Header title="Tentang Univeral" />
      <main
        className={`${styles.paddingX} flex flex-col gap-6 py-10 text-neutral-100`}
      >
        <h2 className={`${styles.headingM} text-center`}>Visi</h2>
        <p className="text-center">
          Mewujudkan Universitas Bereputasi Global Yang Menghasilkan Insan, Ilmu
          Pengetahuan, Teknologi, Seni Dan Budaya Yang Unggul.
        </p>
        <h2 className={`${styles.headingM} text-center`}>Misi</h2>
        <ol className="ml-5 text-justify list-decimal">
          <li>
            Menyelenggarakan Pendidikan Tinggi, Proses Pembelajaran Dan
            Keterampilan Yang Efektif, Berkualitas global dan Profesional Dengan
            Pemanfaatan Teknologi Mukhtahir.
          </li>
          <li>
            Menyelengarakan Penelitian Yang Berkualitas Dan Berkesinambungan
            Berstandarisasi Internasional Agar Bermanfaat Bagi Pengembangan Ilmu
            Serta Kesejahteraan Masyarakat
          </li>
          <li>
            Menyelenggarakan Kegiatan Pengabdian Kepada Masyarakat Berbasis Pada
            Penerapan Hasil Penelitian, Ilmu Pengetahuan, Teknologi, Seni Dan
            Budaya Sebagai Upaya Dalam Mendorong Peningkatan Kesejahteraan
            Masyarakat Secara Global
          </li>
          <li>
            Menerapkan Sistem Tata Kelola Yang Transparan, Akuntabel Dan
            Berstandarisasi Internasional Untuk Mencapai Efisiensi Dan
            Efektivitas Dalam Pengelolaan Sumber Daya
          </li>
          <li>
            Menyelenggarakan Kerjasama Dengan Berbagai Pihak Baik Dalam Maupun
            Luar Negeri Untuk Mengembangkan Institusi Guna Mewujudkan Pendidikan
            Tinggi Yang Mandiri Dan Meningkatkan Reputasi Institusi Pendidikan.
          </li>
        </ol>
        <h2 className={`${styles.headingM} text-center`}>Tujuan</h2>
        <p className={styles.textLMedium}>
          Universitas Almarisah Madani memiliki beberapa tujuan sebagai berikut:
        </p>
        <ol className="ml-5 list-decimal">
          <li>
            Menciptakan Proses Pembelajaran Yang Efektif Dan Profesional Untuk
            Menghasilkan Lulusan Yang Kompeten dan Berdaya Saing Global Dengan
            Pemanfaatan Teknologi Muktahir.
          </li>
          <li>
            <p className="text-justify">
              Meningkatkan Kualitas Penelitian Yang Berstandarisasi
              Internasional Guna Memberikan Solusi Dan Inovasi Yang
              Terdiseminasi Dalam Kehidupan Masyarakat.
            </p>
          </li>
          <li>
            <p className="text-justify">
              Meningkatkan Jumlah Layanan Dan Kerjasama Inovasi baik dalam
              maupun luar negeri Sebagai Bagian Dari Pengabdian Masyarakat.
            </p>
          </li>
          <li>
            <p className="text-justify">
              Mengoptimalkan Pengelolaan Sumber Daya Institusi Pendidikan Secara
              Efektif, Transparan Dan Akuntabel Dalam Rangka Mencapai Good
              University Governance berstandarisasi internasional.
            </p>
          </li>
          <li>
            <p className="text-justify">
              Meningkatan Kemampuan Sumber Daya Manusia Melalui Kerjasama Dalam
              Maupun Luar Negeri Dan Membangun Kemitraan Dengan Berbagai Pihak
              Untuk Meningkatkan Kualitas Institusi Pendidikan.
            </p>
          </li>
        </ol>
      </main>
      <Footer />
    </>
  );
};

export default TentangPage;
